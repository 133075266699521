/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  RACTable,
  RACTableCell,
  RACCheckBox,
  RACTableRow,
  CircularProgress,
  Typography,
} from "@rentacenter/racstrap";
import { globalStyles } from "../Styles/globalstyles";
import { PricingDetailsProvider } from "./PricingContext";
import { PricingReportStyle } from "../Styles/PricingReport";
import ReactPaginate from "react-paginate";
import { ReactComponent as SortIcon } from "../../../assets/images/sort-icon.svg";
import { ReactComponent as SortIconUp } from "../../../assets/images/sort-icon-up.svg";
import { ReactComponent as NorecordsIcon } from "../../../assets/images/No-records.svg";
import { ReactComponent as AttachmentIcon } from '../../../assets/images/attachment icon.svg';
import { ProgressBar } from "../Styles/ProgressStyles";
import moment from "moment";
import TwoFactorAuthentication from "../../shared/TwoStepAuthentication";

export default function PricingGrid() {
  const pricing = PricingReportStyle();
  const progressclasses = ProgressBar();
  const {
    setIsAttachmentOpen,
    pageCount,
    setPricingHeader,approvedBy,setApprovedBy,
    pricingGridData,employeeId,emailId,
    searchBtnTrigger,isApprover,
    currentTableData,isSLACancel,setIsSLACancel,isSLACompleted, setIsSLACompleted,authOpen, setAuthOpen,
    setCurrentTableData,isVisible, setIsVisible,
    pricingHeader,
    setPricingGridData,
    totalRowCount,isFilterOpen,
    fetchpricingGridData,
    selectedItemsArray,
    setSelectedItemsArray,
    loader,
    setLoader,
    pageNumber,
    setPageNumber,
    setSomeThingWentWrng,
    isRecUpdated,
    noRecords,
    setIsRecUpdated,fullloader,setFullloader,
  } = useContext(PricingDetailsProvider);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const classes = globalStyles();
  useEffect(() => {
    console.log("Hello ");
  }, [
    searchBtnTrigger,
    selectAllChecked,
    selectedItemsArray,
    pricingHeader,
    noRecords,
    currentTableData,
  ]);
{
  console.log(pageNumber,pageCount ,"the page number log");
  
}
  /* 
  Fetching the Table Data and maping it into the currentTableData & the pricingTableData,
  Here is the catch if the pagenumber which the index of the pricingTableData is already in then use it else make an API call and fetch the data and update the 
  rec into the currentTableData and insert into pricingTableData with the pagenumber has index
   */

  console.log(pricingGridData, "After fetch pricing");

  /* 
  The update function which is used to set the respective item into the selectedItemArray. 
  Here only the neccessary details to populate and the request payload is set stated into it.
   */
  const updateItemPricingSelection = (
    rowData: any,
    index: any,
    currentPage: any
  ) => {
    debugger;
    let currentArr: any;
    let selectedArr: any;
    // console.log(currentArr[index]);
    currentArr = currentTableData;
    selectedArr = JSON.parse(JSON.stringify(selectedItemsArray));
    let inde = selectedArr.findIndex((item: any)=>{
      if(JSON.stringify(item) ==JSON.stringify(currentArr[index]) )
      return true
      else{
        return false
      }
    })

    if (!currentArr[index].isChecked) {
      if (selectedItemsArray.length == 25) {
      } else {
        currentArr[index].isChecked = true;
        selectedArr.push(currentArr[index]);
      }
    } else {
      selectedArr.splice(inde, 1);
      console.log(selectedItemsArray[inde]);
      delete currentArr[index].isChecked;
    }

    console.log(currentArr, "dif", selectedArr);
    setSelectedItemsArray(selectedArr);
    setCurrentTableData(currentArr);
    setIsRecUpdated(!isRecUpdated);
  };
  console.log(
    selectedItemsArray,
    " The lof of the set state",
    currentTableData
  );

  const sortArray = (
    columnId: string, // columns name with is key to identify the column
    index: number, // the index if the column clicked.
    datacolumn: any, // which is the table header,
    dataValues: any ,// which is the table body.
    isDate: boolean = false
    // the table header and the table body are passed entirely.
  ) => {
    debugger;
    console.log(columnId, "The column sort", dataValues);
    let sortedData;
   
   if(isDate){
        sortedData =
      datacolumn[index].sortDirection == "DESC"
        ? dataValues.sort((a: any, b: any) =>
           {
            let date1 = moment(b[columnId]);
            let date2 = moment(a[columnId]);
            return date2.isBefore(date1) ? -1 : 1;
           }
          )
        : dataValues.sort((a: any, b: any) =>{
          let date1 = moment(b[columnId]);
          let date2 = moment(a[columnId]);
          return date1.isBefore(date2) ? -1 : 1;
        });
   }else{ sortedData  =
      datacolumn[index].sortDirection == "DESC"
        ? dataValues.sort((a: any, b: any) =>
            b[columnId] < a[columnId] ? -1 : 1
          )
        : dataValues.sort((a: any, b: any) =>
            a[columnId] < b[columnId] ? -1 : 1
          );
   }
    const reorderColumns: any = datacolumn;
    reorderColumns[index].sortDirection =
      reorderColumns[index].sortDirection == "ASC" ? "DESC" : "ASC";
    console.log(sortedData, "sort data");

    // reorderColumns.map((value: any, index1: number) => {
    //   reorderColumns[index1].IsSorted = index == index1 ? true : false;
    // });
    setCurrentTableData([...sortedData]);
    setPricingHeader([...reorderColumns]);
    // setState({ gridValues: sortedData, gridColumn: reorderColumns });
  };
  const updateAllPricingSelection = (check: any) => {
    setLoader(true);
    try {
      debugger;
      let arr = pricingGridData ? Object.keys(pricingGridData) : undefined;
      if (arr?.length) {
        arr.forEach((value: any) => {
          pricingGridData[value].forEach((obj: any) => {
            delete obj?.isChecked;
          });
        });
        // if (index in arr) {
        //   // alert("Value present")
        //   console.log(pricingGridData[index]);
        //   setCurrentTableData(pricingGridData[index])
        // }
      }
      let currDataArr = currentTableData;
      let wholeTableData = pricingGridData;
      currDataArr.forEach((obj: any) => {
        if (!check) obj.isChecked = true;
        else delete obj.isChecked;
      });
      setCurrentTableData(currDataArr);
      wholeTableData[pageNumber].forEach((obj: any) => {
        if (!check) obj.isChecked = true;
        else delete obj.isChecked;
      });
      setPricingGridData(wholeTableData);
      setSelectedItemsArray(!check ? currDataArr : []);
    } catch (err) {
      console.log(err, "Error at 181 catch block in update all selction");
    } finally {
      setLoader(false);
    }
  };

  const handlePageClick = (event: any) => {
    scrollTop;
    setPageNumber(event.selected);
    fetchpricingGridData("", (event.selected));
    setSelectAllChecked(false);
    console.log(event.selected, " the page number selected");

    // console.log("pagecount", pageCount,event);
    // {
    //   event.selected === 0 ? setpginitial(0) : setpginitial(event.selected);
    // }
    // const newOffset = (event.selected * 25) % currentTableData.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    // console.log("Current Items");
    // setItemOffset(newOffset);
  };
  const scrollTop = (ref: any) => {
    if (ref) {
      const element = ref;
      console.log("elementsss", element);
      const clientWidth = element.getBoundingClientRect().height;
      console.log("clientWidth refAssignCallback", clientWidth);
      const negCal = clientWidth * 2;
      const negVal = clientWidth - negCal;
      window.scrollBy(0, negVal);
    }
  };
  {
    console.log(
      currentTableData,
      "The current table Data",
      pricingGridData,
      searchBtnTrigger
    );
  }

  console.log(
    selectAllChecked == pageNumber || selectAllChecked != false,
    "Universal",
    selectAllChecked != false,
    pageNumber,
    selectAllChecked
  );

  const renderpricingTableHeader = () => {
    return (
      <>
        {pricingHeader.map((item: any, index: number) => {
          return (
            <>
              {item.heading == "" ? (
                <RACTableCell>
                  <RACCheckBox
                    checked={
                      pricingGridData[pageNumber]?.every((item: any) => {
                        return item.isChecked == true;
                      })
                        ? true
                        : false
                    }
                    // disabled={}
                    onClick={() => {
                      console.log(
                        pricingGridData[pageNumber]?.every((item: any) => {
                          return item.isChecked == true;
                        }),
                        "the data loop in the lopp"
                      );
                      debugger;
                      updateAllPricingSelection(selectAllChecked);
                      setSelectAllChecked(!selectAllChecked);
                    }}
                  />
                </RACTableCell>
              ) : (
                <RACTableCell>
                  {" "}
                  {item.isSortable == true ? (
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        sortArray(
                          pricingHeader[index].key,
                          index,
                          pricingHeader,
                          currentTableData,
                          (item.columnId =='' ||item.columnId =='' ) ? true : false
                        );
                      }}
                    >
                      {" "}
                      {item.heading + " "}{" "}
                      {item.isSorted == true ? (
                        item.sortDirection == "DESC" ? (
                          <SortIconUp className={classes.ml2} />
                        ) : (
                          <SortIcon className={classes.ml2} />
                        )
                      ) : null}
                    </a>
                  ) : (
                    item.heading + " "
                  )}{" "}
                </RACTableCell>
              )}
            </>
          );
        })}
      </>
    );
  };
  const renderpricingTableBody = () => {
    return (
      <>
      {loader ? (
         <RACTableRow className={`${classes.bgTransparent} `}>
         <RACTableCell></RACTableCell>
         <RACTableCell></RACTableCell>
         <RACTableCell></RACTableCell>
         <RACTableCell></RACTableCell>
         <RACTableCell></RACTableCell>
         <RACTableCell></RACTableCell>
         <Grid style={{position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    left: '50%'}}>
           <Grid className={pricing.textSpaceNorecords}>
             <Grid className={pricing.textcenterImage}>
             <CircularProgress />
             </Grid>
           </Grid>
         </Grid>
         <RACTableCell></RACTableCell>
         <RACTableCell></RACTableCell>
         <RACTableCell></RACTableCell>
         <RACTableCell></RACTableCell>
         <RACTableCell></RACTableCell>
         <RACTableCell></RACTableCell>
         <RACTableCell></RACTableCell>
         <RACTableCell></RACTableCell>
         <RACTableCell></RACTableCell>
         <RACTableCell></RACTableCell>
         <RACTableCell></RACTableCell>
         <RACTableCell></RACTableCell>
         <RACTableCell></RACTableCell>
         <RACTableCell>
         </RACTableCell>
         <RACTableCell></RACTableCell>
         <RACTableCell></RACTableCell>
         <RACTableCell></RACTableCell>
       </RACTableRow>
        
      ) : 

        noRecords ? (
          <RACTableRow className={`${classes.bgTransparent} `}>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <Grid>
              <Grid className={pricing.textSpaceNorecords}>
                <Grid className={pricing.textcenterNorecords}>
                  <NorecordsIcon className={pricing.racErrorIcon} />
                </Grid>
                <Grid className={pricing.textcenterImage}>
                  No Records Found !
                </Grid>
              </Grid>
            </Grid>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <Grid>
              <Grid className={pricing.textSpaceNorecords}>
                <Grid className={pricing.textcenterNorecords}>
                  <NorecordsIcon className={pricing.racErrorIcon} />
                </Grid>
                <Grid className={pricing.textcenterImage}>
                  No Records Found !
                </Grid>
              </Grid>
            </Grid>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
            <RACTableCell></RACTableCell>
          </RACTableRow>
        ) : (
          currentTableData?.map((obj: any, index: any) => {
            return (
              <RACTableRow
                key={index}
                className={`${classes.bgTransparent} `}
                style={
                  /* obj.clearanceFlag == "Y"
                    ? { backgroundColor: "#FFF3EA" }
                    : */ obj.eaFlag == "Y"
                    ? { backgroundColor: "#FBF0FF" }
                    : { backgroundColor: "white" }
                }
              >
                <RACTableCell
                  style={
                   /*  obj.clearanceFlag == "Y"
                      ? { backgroundColor: "#FFF3EA" }
                      : */ obj.eaFlag == "Y"
                      ? { backgroundColor: "#FBF0FF" }
                      : { backgroundColor: "white" }
                  }
                  className={`${
                   /*  obj.clearanceFlag == "Y"
                      ? classes.Orangetag
                      : */ obj.eaFlag == "Y"
                      ? classes.Purpletag
                      : null
                  }`}
                >
                  <RACCheckBox
                    checked={obj.isChecked ? true : false}
                    onClick={() => {
                      updateItemPricingSelection(obj, index, pageNumber);
                    }}
                  />
                </RACTableCell>
                <RACTableCell
                  /* style={{ width: "10% !important" } */ style={
                    /* obj.clearanceFlag == "Y"
                      ? { backgroundColor: "#FFF3EA" }
                      : */ obj.eaFlag == "Y"
                      ? { backgroundColor: "#FBF0FF" }
                      : { backgroundColor: "white" }
                  }
                >
                  {obj.zoneNumber ? obj.zoneNumber : "-"}
                </RACTableCell>
                <RACTableCell
                  /*  */ style={
                   /*  obj.clearanceFlag == "Y"
                      ? { backgroundColor: "#FFF3EA" }
                      : */ obj.eaFlag == "Y"
                      ? { backgroundColor: "#FBF0FF" }
                      : { backgroundColor: "white" }
                  }
                >
                  {obj.rmsItemNumber ? obj.rmsItemNumber : "-"}
                </RACTableCell>
                <RACTableCell
                  /*  style={{ width: "10% !important", padding: "20px" }} */
                  style={
                    /* obj.clearanceFlag == "Y"
                      ? { backgroundColor: "#FFF3EA" }
                      : */ obj.eaFlag == "Y"
                      ? { backgroundColor: "#FBF0FF" }
                      : { backgroundColor: "white" }
                  }
                  title={obj.rmsItemDescription}
                >
                  {obj.rmsItemDescription.slice(0, 25) + "..."}
                </RACTableCell>
                <RACTableCell>
                  {obj.departmentName ? obj.departmentName : "-"}
                </RACTableCell>
                <RACTableCell>
                  {obj.subdepartmentName ? obj.subdepartmentName : "-"}
                </RACTableCell>
                <RACTableCell title={obj.bracketName ? obj.bracketName.length>25 ?obj.bracketName : '':''}>
                  {obj.bracketName ? obj.bracketName.length>25 ?(obj.bracketName.slice(0, 25) + "..." ):obj.bracketName : "-"}
                </RACTableCell>
                <RACTableCell>
                  {obj.brandName ? obj.brandName : "-"}
                </RACTableCell>
                <RACTableCell>
                  {obj.modelNumber ? obj.modelNumber : "-"}
                </RACTableCell>
                <RACTableCell>
                  {obj.pricingType ? obj.pricingType : "-"}
                </RACTableCell>
                <RACTableCell>
                  {obj.purchaseCost ? `$${obj.purchaseCost}` : "-"}
                </RACTableCell>
                <RACTableCell>
                  {obj.weeklyRateNew ? `$${obj.weeklyRateNew}` : "-"}
                </RACTableCell>
                <RACTableCell>
                  {obj.weeklyRateUsed ? `$${obj.weeklyRateUsed}` : "-"}
                </RACTableCell>
                <RACTableCell>
                  {obj.cashPriceNew ? `$${obj.cashPriceNew}` : "-"}
                </RACTableCell>
                
                <RACTableCell>
                  {obj.cashPriceTurn ? obj.cashPriceTurn : "-"}
                </RACTableCell>
                
                <RACTableCell>
                  {obj.totalCostNew ? `$${obj.totalCostNew}` : "-"}
                </RACTableCell>
                
                <RACTableCell>
                  {obj.totalRtoTurn ? obj.totalRtoTurn : "-"}
                </RACTableCell>
                
                <RACTableCell>
                  {obj.cashPriceUsed ? `$${obj.cashPriceUsed}` : "-"}
                </RACTableCell>
                
                <RACTableCell>
                  {obj.totalCostUsed ? `$${obj.totalCostUsed}` : "-"}
                </RACTableCell>
                <RACTableCell>{obj.term ? obj.term : "-"}</RACTableCell>
                <RACTableCell>
                  {obj.cashPriceFactor ? obj.cashPriceFactor : "-"}
                </RACTableCell>
                <RACTableCell>
                  {obj.forcedCashPrice ? obj.forcedCashPrice : "-"}
                </RACTableCell>
                <RACTableCell>
                  {obj.manualPoTurns ? obj.manualPoTurns : "-"}
                </RACTableCell>
                <RACTableCell>
                  {obj.startDate
                    ? moment(obj.startDate).format("MM/DD/YYYY")
                    : "-"}
                </RACTableCell>
                <RACTableCell>
                  {obj.endDate ? moment(obj.endDate).format("MM/DD/YYYY") : "-"}
                </RACTableCell>
                <RACTableCell style={{ cursor: "pointer" }}>
                  <AttachmentIcon onClick={() => setIsAttachmentOpen(index)} />
                </RACTableCell>
              </RACTableRow>
            );
          })
        )}
        
      </>
    );
  };

  return (
    <>
      {fullloader ? (
        <Grid className={progressclasses.masterLoader}>
          <Grid className={progressclasses.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      {!((pricingGridData && searchBtnTrigger) /*  && noRecords */) ? null : (
        <Card ref={scrollTop}  style={{borderRadius:'12px', position:"relative",marginBottom:'4%'}}>
          <CardContent style={{paddingBottom:'0px'}}>
            <Grid
              style={{
                width: "100%",
                overflowX: "auto",
                maxHeight: "63vh",
                minHeight: isFilterOpen && loader ? '38vh' : '61vh'

              }}
            >
              <RACTable
                renderTableContent={renderpricingTableBody}
                renderTableHead={renderpricingTableHeader}
                style={{
                  width: "100%",
                  whiteSpace: "nowrap",
                }}
                className={classes.PricingGrid}
              />
            </Grid>
            {totalRowCount ? (<Grid className={` ${classes.textRight}`}>
              <Grid className={`${pricing.totrec}`}>
                
                  <Typography>
                    # of Records:&nbsp;
                    {pageNumber == 0
                      ? totalRowCount < 25 ? totalRowCount :25 * 1
                      : (pageNumber+1) == pageCount
                      ? totalRowCount
                      : (pageNumber + 1) * 25}
                    /{totalRowCount}
                    {selectedItemsArray.length ? "|" : ""}
                    {selectedItemsArray.length ? (
                      <span style={{ color: "blue" }}>
                        {selectedItemsArray.length} out of 25{" "}
                      </span>
                    ) : (
                      ""
                    )}{" "}
                    {selectedItemsArray.length ? (
                      <span>records are selected.</span>
                    ) : (
                      ""
                    )}
                  </Typography>
              </Grid>
             
                <ReactPaginate
                  nextLabel=">"//1
                  onPageChange={(num: any) => handlePageClick(num)}//2
                  pageCount={pageCount}//4
                  pageRangeDisplayed={3}//5
                  marginPagesDisplayed={0}//6
                  disableInitialCallback={true}
                  previousLabel="<"//7
                  breakLabel=""//12
                  initialPage={pageNumber}//3
                  pageClassName={pricing.pagination}//8
                  pageLinkClassName={pricing.pagelink}//9
                  previousLinkClassName={
                    pageNumber === 0
                      ? pricing.paginationprevnextdisable
                      : pricing.paginationprevnext
                  }//10
                  nextLinkClassName={
                    pageNumber === pageCount - 1
                      ? pricing.paginationprevnextdisable
                      : pricing.paginationprevnext
                  }//11
                  breakClassName={pricing.pagination}
                  breakLinkClassName={pricing.pagination}
                  containerClassName={pricing.pagination}
                  activeClassName={pricing.pageactive}
                  forcePage={pageNumber === 0 ? 0 : undefined} //new logic
                />
              
            </Grid>) : null}
          </CardContent>
        </Card>
      )}
            <TwoFactorAuthentication
              IsHigherAuthority={isApprover} isVisible={authOpen} setIsVisible={setAuthOpen} loader={fullloader}setloader={setFullloader} setTwoFactorCompleted={setIsSLACompleted} setTwoFactorCancelClick={setIsSLACancel} twoFactorCompleted={isSLACompleted} twoFactorCancelClick={isSLACancel} setSomethingWentWrong={setSomeThingWentWrng} mailId={emailId} employeeId={employeeId} submitedBy ={sessionStorage.getItem('userName')} ApprovedBy={setApprovedBy}/>
    </>
  );
}
