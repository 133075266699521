/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  RACButton,
  RACDatePicker,
  RACModalCard,
  RACRadio,
  RACSelect,
  RACTextbox,
  Typography,
} from "@rentacenter/racstrap";
import { PricingDetailsProvider } from "./PricingContext";
import { MultiSelect } from "react-multi-select-component";
import { ProgressBar } from "../Styles/ProgressStyles";
import { ReactComponent as DangerIcon } from "../../../assets/images/danger.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/images/success-icon.svg";
import { ReactComponent as WarningIcon } from "../../../assets/images/no-records-found.svg";
import moment from "moment";
import { ReactComponent as DefaultImage } from "../../../assets/images/Default Image.svg";
import { globalStyles } from "../Styles/globalstyles";
import {   addPricingQueueAPI } from "../../../api/user";
export default function PricingFooter() {
  const classes = ProgressBar();
  const classes1 = globalStyles();
  let rmsItemDetails: any = [];
  let count = 0;
  const {
    isAttachmentOpen,
    setIsAttachmentOpen,
    zoneOptions,setFullloader,isApprovalOpen, setIsApprovalOpen,
    pricingGridData,isApproved, setIsApproved,isFranchiseeUser,
    isNewPricingOpen,companyCode,updatePricingQueueStatus,
    type,
    setLoader,
    pageNumber,
    setIsNewPricingOpen,
    selectedItemsArray,
    setRmsItemDetailsArr,approvedBy,
    setSelectedItemsArray,
    isUpdatePricingOpen,
    setIsUpdatePricingOpen,
    setPricingQueueId,
    formBackupData,
    setFormBackupData,
    addPricingRequestOject,
    addPricingReq,
    setAddPricingReq,
    someThingWentWrng,setAuthOpen,
    setSomeThingWentWrng,
  } = useContext(PricingDetailsProvider);

  const [isUpdateWarningOpen, setIsUpdateWarningOpen] = useState(false);
  const [zoneSelected, setZoneSelected] = useState([]);
  const [rmsItemSelected, setRmsItemSelected] = useState("");
  console.log(pricingGridData, "The pricing grid data by nagaarjun");

  let imageSource: any;
  useEffect(() => {
    imageSource = `${
      pricingGridData
        ? pricingGridData[`${pageNumber}`][isAttachmentOpen]?.productImg
        : null
    }`;
  }, [isAttachmentOpen]);

  useEffect(() => {
    populateValue();
  }, [isUpdatePricingOpen]);

  const validateDatesRange = (input1: string, input2: string) => {
    const date1 = moment(input1, "YYYY-MM-DD");
    const date2 = moment(input2, "YYYY-MM-DD");
    if (date1.isAfter(date2) || date1.isSame(date2)) return true;
    else return false;
  };
  const ManageRMSDetails = () => {
    debugger;
    let rmsDetail: any = [];
    zoneSelected.forEach((element: any) => {
      rmsDetail.push({
        zone: element.value,
        rmsItemNumber: [rmsItemSelected],
      });
    });
    return rmsDetail;
  };
  const validationPricingInputs = (obj : any) => {
    debugger;
    let pricingDetails = obj;
    if (obj.type == "TEMPORARY" && obj.endDate)
      if (obj.startDate)
        validateDatesRange(obj.startDate, obj.endDate);
      else {
        obj.startDate = `${moment(new Date()).format("YYYY-MM-DD")}`;
        validateDatesRange(obj.startDate, obj.endDate);
      }

    if (
      obj.type == "PERMANENT" ||
      obj.type == "TEMPORARY"
    ) {
      pricingDetails.manualPOTurns = "";
    }
    if(obj.type=='MANUAL' && pricingDetails.term && (pricingDetails.weeklyRateNew!='' || pricingDetails.weeklyRateUsed!='')) //new condition
    {
      pricingDetails.term='';
      pricingDetails.weeklyRateNew='';
      pricingDetails.weeklyRateUsed='';

    }
    if(obj.type == "PERMANENT" || obj.type == "MANUAL") // New condition
    pricingDetails.endDate = ""; 
    pricingDetails.totalCostNew = (
      pricingDetails?.weeklyRateNew * pricingDetails?.term
    ).toFixed(2);
    pricingDetails.cashPriceNew = pricingDetails?.forcedCashPrice ? pricingDetails.forcedCashPrice : (
      pricingDetails?.totalCostNew * pricingDetails?.cashPriceFactor
    ).toFixed(2);
    pricingDetails.totalCostUsed = (
      pricingDetails?.weeklyRateUsed * pricingDetails?.term
    ).toFixed(2);
    pricingDetails.cashPriceUsed = pricingDetails?.forcedCashPrice? pricingDetails.forcedCashPrice: (
       pricingDetails?.totalCostUsed * pricingDetails?.cashPriceFactor
    ).toFixed(2);
    
    setAddPricingReq(pricingDetails);
    return true;
  };
  const populateValue = async () => {
    let zoneMap = new Map();

    if (selectedItemsArray.length > 0 && isUpdatePricingOpen) {
      selectedItemsArray.forEach((item: any) => {
        if (!zoneMap.has(item.zoneNumber)) {
          zoneMap.set(item.zoneNumber, [item.rmsItemNumber]);
        } else {
          zoneMap.get(item.zoneNumber).push(item.rmsItemNumber);
        }
      });
      for (const [zone, rmsItemNumbers] of zoneMap.entries()) {
        rmsItemDetails.push({
          zone,
          rmsItemNumber: rmsItemNumbers,
        });
      }
      setRmsItemDetailsArr(rmsItemDetails);
      if (selectedItemsArray.length === 1) {
        count++;
      } else {
        for (let i = 0; i < selectedItemsArray.length; i++) {
          if (selectedItemsArray[0] == selectedItemsArray[i]) {
            count++;
          } else {
            count = 0;
            break;
          }
        }
      }
      if (count) {
        setAddPricingReq({
          ...addPricingReq,
          ["type"]: selectedItemsArray[0].pricingType,
          ["weeklyRateNew"]: selectedItemsArray[0].pricingType?.toLowerCase() == 'manual' && selectedItemsArray[0].weeklyRateNew && selectedItemsArray[0].weeklyRateUsed ? '':selectedItemsArray[0].weeklyRateNew ,
          ["weeklyRateUsed"]: selectedItemsArray[0].pricingType?.toLowerCase() == 'manual' && selectedItemsArray[0].weeklyRateNew && selectedItemsArray[0].weeklyRateUsed ?'' :selectedItemsArray[0].weeklyRateUsed,
          ["term"]: selectedItemsArray[0].pricingType?.toLowerCase() == 'manual' && selectedItemsArray[0].weeklyRateNew && selectedItemsArray[0].weeklyRateUsed ?'' :selectedItemsArray[0].term,
          ["cashPriceFactor"]: selectedItemsArray[0].cashPriceFactor && selectedItemsArray[0].forcedCashPrice? '':selectedItemsArray[0].cashPriceFactor,
          ["forcedCashPrice"]:  selectedItemsArray[0].cashPriceFactor && selectedItemsArray[0].forcedCashPrice? '':selectedItemsArray[0].forcedCashPrice ? selectedItemsArray[0].forcedCashPrice : '',
          ["manualPOTurns"]: selectedItemsArray[0].manualPoTurns ? selectedItemsArray[0].manualPoTurns : '',
          ["cashPriceNew"]: selectedItemsArray[0].cashPriceNew,
          ["totalCostNew"]: selectedItemsArray[0].totalCostNew,
          ["cashPriceUsed"]: selectedItemsArray[0].cashPriceUsed,
          ["totalCostUsed"]: selectedItemsArray[0].totalCostUsed,
          ["startDate"]: moment(
            new Date(selectedItemsArray[0].startDate)
          ).format("YYYY-MM-DD"),
          ["endDate"]: selectedItemsArray[0].pricingType?.toLowerCase() == 'permanent' ? '': moment(new Date(selectedItemsArray[0].endDate)).format(
            "YYYY-MM-DD"
          ),
          ["queueType"]: selectedItemsArray[0].queueType,
          ["rmsItemDetails"]: rmsItemDetails,
        });
        setFormBackupData({
          ...addPricingReq,
          ["type"]: selectedItemsArray[0].pricingType,
          ["weeklyRateNew"]: selectedItemsArray[0].weeklyRateNew,
          ["weeklyRateUsed"]: selectedItemsArray[0].weeklyRateUsed,
          ["term"]: selectedItemsArray[0].term,
          ["cashPriceFactor"]: selectedItemsArray[0].cashPriceFactor,
          ["forcedCashPrice"]: selectedItemsArray[0].forcedCashPrice ? selectedItemsArray[0].forcedCashPrice : '',
          ["manualPOTurns"]: selectedItemsArray[0].manualPoTurns ? selectedItemsArray[0].manualPoTurns : '',
          ["cashPriceNew"]: selectedItemsArray[0].cashPriceNew,
          ["totalCostNew"]: selectedItemsArray[0].totalCostNew,
          ["cashPriceUsed"]: selectedItemsArray[0].cashPriceUsed,
          ["totalCostUsed"]: selectedItemsArray[0].totalCostUsed,
          ["startDate"]: moment(
            new Date(selectedItemsArray[0].startDate)
          ).format("YYYY-MM-DD"),
          ["endDate"]: selectedItemsArray[0].pricingType?.toLowerCase() == 'permanent' ? '':  moment(new Date(selectedItemsArray[0].endDate)).format(
            "YYYY-MM-DD"
          ),
          ["queueType"]: selectedItemsArray[0].queueType,
          ["rmsItemDetails"]: rmsItemDetails,
        });
      } else {
        setAddPricingReq({
          ...addPricingReq,
          ["rmsItemDetails"]: rmsItemDetails,
          ["type"]: selectedItemsArray[0].pricingType,
        });
        setFormBackupData({
          ...addPricingReq,
          ["rmsItemDetails"]: rmsItemDetails,
          ["type"]: selectedItemsArray[0].pricingType,
        });
      }
    }
  };
  const attachmentsPopUp = () => {
    if (pricingGridData && isAttachmentOpen >= 0)
      return (
        <>
          <Grid container spacing={2} className={`${classes1.p3}`}>
            <Grid
              style={{ display: "flex", width: "100%", gap: "24px" }}
              alignItems="center"
            >
              <Grid style={{ height: "146px", width: "239px" }}>
                {imageSource ? (
                  <img
                    src={imageSource}
                    alt="Image not found"
                    width={"100%"}
                    height={"100%"}
                    style={{ objectFit: "contain" }}
                  />
                ) : (
                  <Grid>
                    <DefaultImage style={{ objectFit: "contain" }} />
                  </Grid>
                )}
              </Grid>
              <Grid container style={{ width: "100%" }} alignItems="center">
                <Grid sm={6} md={4} lg={3} className={`${classes1.mb4}`}>
                  <Typography style={{ display: "inline" }}>Zone:</Typography>{" "}
                  <Typography
                    style={{
                      display: "inline",
                      fontWeight: "bold",
                      fontFamily: "OpenSans-bold",
                    }}
                  >
                    {pricingGridData[pageNumber][isAttachmentOpen]?.zoneNumber
                      ? pricingGridData[pageNumber][isAttachmentOpen]
                          ?.zoneNumber
                      : "-"}
                  </Typography>
                </Grid>
                <Grid sm={6} md={4} lg={3} className={`${classes1.mb4}`}>
                  <Typography style={{ display: "inline" }}>RMS#:</Typography>{" "}
                  <Typography
                    style={{
                      display: "inline",
                      fontWeight: "bold",
                      fontFamily: "OpenSans-bold",
                    }}
                  >
                    {pricingGridData[pageNumber][isAttachmentOpen]
                      ?.rmsItemNumber
                      ? pricingGridData[pageNumber][isAttachmentOpen]
                          ?.rmsItemNumber
                      : "-"}
                  </Typography>
                </Grid>
                <Grid sm={6} md={4} lg={3} className={`${classes1.mb4}`}>
                  <Typography style={{ display: "inline" }}>
                    Department:
                  </Typography>{" "}
                  <Typography
                    style={{
                      display: "inline",
                      fontWeight: "bold",
                      fontFamily: "OpenSans-bold",
                    }}
                  >
                    {pricingGridData[pageNumber][isAttachmentOpen]
                      ?.departmentName
                      ? pricingGridData[pageNumber][isAttachmentOpen]
                          ?.departmentName
                      : "-"}
                  </Typography>
                </Grid>
                <Grid sm={6} md={4} lg={3} className={`${classes1.mb4}`}>
                  <Typography style={{ display: "inline" }}>
                    Sub Department:
                  </Typography>{" "}
                  <Typography
                    style={{
                      display: "inline",
                      fontWeight: "bold",
                      fontFamily: "OpenSans-bold",
                    }}
                  >
                    {pricingGridData[pageNumber][isAttachmentOpen]
                      ?.subdepartmentName
                      ? pricingGridData[pageNumber][isAttachmentOpen]
                          ?.subdepartmentName
                      : "-"}
                  </Typography>
                </Grid>
                <Grid sm={6} md={4} lg={3} className={`${classes1.mb4}`}>
                  <Typography style={{ display: "inline" }}>
                    Bracket:
                  </Typography>{" "}
                  <Typography
                    style={{
                      display: "inline",
                      fontWeight: "bold",
                      fontFamily: "OpenSans-bold",
                    }}
                  >
                    {pricingGridData[pageNumber][isAttachmentOpen]?.bracketName
                      ? pricingGridData[pageNumber][isAttachmentOpen]
                          ?.bracketName
                      : "-"}
                  </Typography>
                </Grid>
                <Grid sm={6} md={4} lg={3} className={`${classes1.mb3}`}>
                  <Typography style={{ display: "inline" }}>Brand:</Typography>{" "}
                  <Typography
                    style={{
                      display: "inline",
                      fontWeight: "bold",
                      fontFamily: "OpenSans-bold",
                    }}
                  >
                    {pricingGridData[pageNumber][isAttachmentOpen]?.brandName
                      ? pricingGridData[pageNumber][isAttachmentOpen]?.brandName
                      : "-"}
                  </Typography>
                </Grid>
                <Grid sm={6} md={4} lg={3} className={`${classes1.mb3}`}>
                  <Typography style={{ display: "inline" }}>
                    Model #:
                  </Typography>{" "}
                  <Typography
                    style={{
                      display: "inline",
                      fontWeight: "bold",
                      fontFamily: "OpenSans-bold",
                    }}
                  >
                    {pricingGridData[pageNumber][isAttachmentOpen]?.modelNumber
                      ? pricingGridData[pageNumber][isAttachmentOpen]
                          ?.modelNumber
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    else return <></>;
  };

  const onchangeInputfield = (key: any, e: any) => {
    console.log(key, "Key in the onChangeInputField", e);
    let obj = JSON.parse(JSON.stringify(addPricingReq));
    e = e.replace(/^0+(?=\d)/, "");

    let typeArr = [
      "type",
      "startDate",
      "endDate",
      "zone",
      "queueType",
      // "clearanceFlag",
    ];
    if (typeArr.includes(key)) {
      obj[key]= e;
    } else if(key == 'cashPriceFactor'){
      debugger;
      if(e == '.')
         e = e.replace('.','0.');
      if(e.match(/^[1-9]/)){
        console.log('match',e);
        
        e = e.replace(e,'');
      }
      console.log(e, "tbehg jreklrhvkj")
      e = e.replace(/(\.\d*)\..*$/, "$1"); // Remove any subsequent decimal points after the first one
      e = e.replace(/[^\d.]+/, ""); // Remove all non-numeric characters except .

      e = e.replace(/^0*(\.)?(\.[0-9]{3})\d+?$/,"$1")
      obj[key]= e;


    } else{
      if (key != "term") {
        e = e.replace(/^[^\d]*(\d+(\.\d*)?)?/, "$1"); // Remove any non-numeric characters at the beginning, allowing only one optional decimal point after at least one digit
        e = e.replace(/[^\d.]+/, ""); // Remove all non-numeric characters except .
        e = e.replace(/(\.\d*)\..*$/, "$1"); // Remove any subsequent decimal points after the first one
        e = e.replace(/(\.\d{3})\d+?$/, "");
      } else {
        e = e.replace(/[^\d]/g, "");
      }
      obj[key]= e;

    }
    validationPricingInputs(obj)
  };
  const managePricing = async () => {
    try {
      if (validationPricingInputs(addPricingReq)) {
        setIsNewPricingOpen(!isNewPricingOpen);
        setIsUpdatePricingOpen(false);
        setFullloader(true);
        let pricingRequest = addPricingReq;
        let arr = Object.keys(addPricingReq);
        arr.forEach((item: any) => {
          if (pricingRequest[item] == "" || pricingRequest[item] == null)
            delete pricingRequest[item];
        });

        if (!isUpdatePricingOpen)
          pricingRequest.rmsItemDetails = ManageRMSDetails();
        const date1 = moment(pricingRequest.startDate, "YYYY-MM-DD");
        const date2 = moment(pricingRequest.endDate, "YYYY-MM-DD");
        const date3 = moment().add(1, "day").format("YYYY MM DD");
        if (pricingRequest.startDate) {
          if (date1.isBefore(date3))
            pricingRequest.startDate = `${pricingRequest.startDate} ${moment()
              .format("hh:mm:ss")
              .toString()}`;
          else
            pricingRequest.startDate = pricingRequest.startDate + " 00:00:00";
        }
        if (pricingRequest.endDate) {
          if (date2.isBefore(date3))
            pricingRequest.endDate =
              pricingRequest.endDate +
              moment().format("YYYY MM DD hh:mm:ss").toString();
          else pricingRequest.endDate = pricingRequest.endDate + " 00:00:00";
        }
        let queueType = pricingRequest.queueType
          ? pricingRequest.queueType
          : "IMMEDIATE";
          let manualP0Turns = pricingRequest['manualPOTurns']
        let deleteArr = [
          "queueType",
          "cashPriceNew",
          "totalCostNew",
          "cashPriceUsed",
          "totalCostUsed",
          'manualPOTurns'
        ];
        deleteArr.forEach((item) => delete pricingRequest[item]);
        pricingRequest['manualPoTurns'] = manualP0Turns;
        let req = {
          schedule: queueType,
          status: "WAITING",
          submittedBy: window.sessionStorage.getItem('userName'),
          type: "PRICING",
          companyCode:companyCode ? companyCode[0]: undefined,
          queueData: {
            pricingDetails: pricingRequest,
          },
        };

        const response = await addPricingQueueAPI(req);
        console.log(response, "The data from the add service");
        debugger;
        console.log(pricingGridData, "the datae to be pricng ");

        if (response.status == 200 && response.data.pricingQueueId) {
          //dff
          setSelectedItemsArray([]);
          if(pricingGridData && Object?.keys(pricingGridData)?.length)
          Object?.keys(pricingGridData)?.forEach((element: any, index : any) => {
            pricingGridData[element].forEach((ele : any) =>{ delete ele.isChecked})
          });
          
          setAddPricingReq(addPricingRequestOject);
          setZoneSelected([]);
          setPricingQueueId(response.data.pricingQueueId);
          setIsApprovalOpen(true);
          console.log(response);
        } else {
          throw "Err";
        }
      }
    } catch (err) {
      console.log(err);
      setSomeThingWentWrng(true);
    } finally {
      setFullloader(false);
    }
  };
  const pricingPopUp = () => {
    return (
      <>
        <Grid container md={12} spacing={4} style={{margin:'0px'}}>
          {isNewPricingOpen && !isUpdatePricingOpen ? (
            <>
              <Grid item md={4} className={classes.multiSelect}>
                <Typography>
                  Zone<span style={{ color: "red" }}>*</span>
                </Typography>
                <MultiSelect
                  options={zoneOptions}
                  value={zoneSelected}
                  isLoading={zoneOptions?.length>0 || (isFranchiseeUser && companyCode.length ==0) ? false: true}
                  onChange={(e: any) => {
                    console.log(e);
                    let arr = e.map((item: any) => {
                      if (item.value == item.value) return item.value;
                    });
                    setZoneSelected(e);
                  }}
                  labelledBy={"Select"}
                />
              </Grid>
              <Grid item md={4} className={classes.currencyInputStyle}>
                <RACTextbox
                  maxlength={20}
                  value={addPricingReq.rmsItemNumber}
                  name="RMS #"
                  inputlabel="RMS #"
                  required
                  OnChange={(e) => {
                    e.target.value = e.target.value.replace(/[^\d]/g, "");
                    setRmsItemSelected(e.target.value);
                  }}
                ></RACTextbox>
              </Grid>
            </>
          ) : null}
          <Grid item md={4} className={classes.racSelect}>
            {isNewPricingOpen && isUpdatePricingOpen ? (
              <RACSelect
                inputLabel="Type"
                defaultValue={addPricingReq.type ? addPricingReq.type : ""}
                options={type}
                isDisabled={true}
                onChange={(e) => onchangeInputfield("type", e.target.value)}
              />
            ) : (
              <RACSelect
                inputLabel="Type"
                required
                defaultValue={addPricingReq.type ? addPricingReq.type : ""}
                options={type}
                onChange={(e) => {
                  console.log("The log ", e);

                  onchangeInputfield("type", e.target.value);
                }}
              />
            )}
          </Grid>
          <Grid item md={4} className={classes.currencyInputStyle}>
            <RACTextbox
              value={addPricingReq.weeklyRateNew ?  addPricingReq.weeklyRateNew  :''}
              disabled={
                addPricingReq.type == "MANUAL" && addPricingReq.term 
                  ? true
                  : false
              }
              name="Weekly Rate New"
              required ={addPricingReq.type == "PERMANENT" || addPricingReq.type == "TEMPORARY" }
              isCurrency={true}
              inputlabel="Weekly Rate New"
              OnChange={(e) =>
                onchangeInputfield("weeklyRateNew", e.target.value)
              }
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={classes.currencyInputStyle}>
            <RACTextbox
              value={addPricingReq.weeklyRateUsed ? addPricingReq.weeklyRateUsed :''}
              name="Weekly Rate Used"
              required ={addPricingReq.type == "PERMANENT" || addPricingReq.type == "TEMPORARY" }
              disabled={
                addPricingReq.type == "MANUAL" && addPricingReq.term
                  ? true
                  : false
              }
              isCurrency={true}
              inputlabel="Weekly Rate Used"
              OnChange={(e) =>
                onchangeInputfield("weeklyRateUsed", e.target.value)
              }
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={classes.currencyInputStyle}>
            <RACTextbox
              value={addPricingReq.term ?addPricingReq.term :''}
              required ={addPricingReq.type == "PERMANENT" || addPricingReq.type == "TEMPORARY" }
              disabled={
                addPricingReq.type == "MANUAL" &&
                (addPricingReq.weeklyRateNew || addPricingReq.weeklyRateUsed)
                  ? true
                  : false
              }
              name="Term"
              inputlabel="Term"
              OnChange={(e) => onchangeInputfield("term", e.target.value)}
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={classes.currencyInputStyle}>
            <RACTextbox
              value={addPricingReq.cashPriceFactor ? addPricingReq.cashPriceFactor :''}
              name="Cash Price Factor"
              inputlabel="Cash Price Factor"
              disabled={addPricingReq.forcedCashPrice ? true : false}
              OnChange={(e) =>{

                
                onchangeInputfield("cashPriceFactor", e.target.value)
              }
              }
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={classes.currencyInputStyle}>
            <RACTextbox
              value={addPricingReq.forcedCashPrice ? addPricingReq.forcedCashPrice  :''}
              name="Forced Cash Price"
              disabled={addPricingReq.cashPriceFactor ? true : false}
              inputlabel="Forced Cash Price"
              OnChange={(e) =>
                onchangeInputfield("forcedCashPrice", e.target.value)
              }
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={classes.currencyInputStyle}>
            <RACTextbox
              value={addPricingReq.manualPOTurns}
              name="Manual Po-Turns"
              disabled={addPricingReq.type == "MANUAL" ? false : true}
              required={addPricingReq.type == "MANUAL" }
              inputlabel="Manual Po-Turns"
              OnChange={(e) =>
                onchangeInputfield("manualPOTurns", e.target.value)
              }
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={`${classes.overrideDisbaleInCurrencyInp}  `}>
            <RACTextbox
              value={addPricingReq.cashPriceNew}
              name="Cash Price New"
              inputlabel="Cash Price New"
              isCurrency={true}
              disabled={true}
              className={classes.currencyTextBoxBlue}
              OnChange={(e) =>
                onchangeInputfield("cashPriceNew", e.target.value)
              }
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={classes.overrideDisbaleInCurrencyInp}>
            <RACTextbox
              value={addPricingReq.totalCostNew}
              name="Total Cost New"
              inputlabel="Total Cost New"
              isCurrency={true}
              disabled={true}
              className={classes.currencyTextBoxBlue}
              OnChange={(e) =>
                onchangeInputfield("totalCostNew", e.target.value)
              }
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={classes.overrideDisbaleInCurrencyInp}>
            <RACTextbox
              value={addPricingReq.cashPriceUsed}
              name="Cash Price Used"
              inputlabel="Cash Price Used"
              isCurrency={true}
              disabled={true}
              className={classes.currencyTextBoxBlue}
              OnChange={(e) =>
                onchangeInputfield("cashPriceUsed", e.target.value)
              }
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={classes.overrideDisbaleInCurrencyInp}>
            <RACTextbox
              value={addPricingReq.totalCostUsed}
              name="Total Cost Used"
              inputlabel="Total Cost Used"
              isCurrency={true}
              disabled={true}
              className={classes.currencyTextBoxBlue}
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={classes.currencyInputStyle}>
            {addPricingReq.endDate ? (
              <RACDatePicker
                label={"Start Date"}
                required
                inputProps={{
                  min: moment().format("YYYY-MM-DD"),
                  max: moment(addPricingReq.endDate)
                    .add(1, "day")
                    .format("YYYY-MM-DD"),
                }}
                value={addPricingReq.startDate}
                onChange={(e: any) => onchangeInputfield("startDate", e)}
              ></RACDatePicker>
            ) : (
              <RACDatePicker
                label={"Start Date"}
                required
                inputProps={{
                  min: moment().format("YYYY-MM-DD"),
                }}
                value={addPricingReq.startDate}
                onChange={(e: any) => onchangeInputfield("startDate", e)}
              ></RACDatePicker>
            )}
          </Grid>
          <Grid item md={4} className={classes.currencyInputStyle}>
            <RACDatePicker
              label={"End Date"}
              required={addPricingReq.type == 'TEMPORARY'? true : false}
              disabled={addPricingReq.type == "PERMANENT" || addPricingReq.type=='MANUAL' ? true : false}
              value={addPricingReq.type == "PERMANENT" || addPricingReq.type == 'MANUAL' ? "2099-12-31" :addPricingReq.endDate}
              inputProps={{
                min: moment().format("YYYY-MM-DD"),
              }}
              onChange={(e: any) => onchangeInputfield("endDate", e)}
            ></RACDatePicker>
          </Grid>

        </Grid>

        {/* {isNewPricingOpen && isUpdatePricingOpen ? */} 
          <>
            <Grid spacing={4} style={{ marginTop: "1%", marginLeft:'2%' }}>
              <Typography> How do you want to update the pricing? <span style={{color:'red'}}>*</span></Typography>
              <Grid
                style={{
                  margin: "1%",
                }}
              >
                <RACRadio
                  value={""}
                  checked={
                    addPricingReq.queueType == "IMMEDIATE" ? true : false
                  }
                  label="Immediately"
                  name="updateType"
                  onClick={() => onchangeInputfield("queueType", "IMMEDIATE")}
                ></RACRadio>
                <RACRadio
                  value={""}
                  checked={
                    addPricingReq.queueType == "OVERNIGHT" ? true : false
                  }
                  label="Overnight"
                  name="updateType"
                  onClick={() => onchangeInputfield("queueType", "OVERNIGHT")}
                ></RACRadio>
              </Grid>
              <Typography>
                <span style={{ fontFamily: "OpenSans-bold" }}>Note: </span>
                <span
                  style={{
                    fontWeight: "lighter",
                    fontFamily: "OpenSans-regular",
                  }}
                >
                  The selected criteria matches with {selectedItemsArray.length}{" "}
                  RMS record
                </span>
              </Typography>
            </Grid>
          </>
         {/* : null} */}

        <Grid
          style={{
            float: "right",
            width: "100%",
            display: "flex",
            flexDirection: "row-reverse",
          }}
          md={12}
        >
          <RACButton
            variant="contained"
            color="primary"
            size="small"
            disabled={
              ((isNewPricingOpen && !isUpdatePricingOpen && zoneSelected.length!= 0 && rmsItemSelected!=''&& addPricingReq.queueType )|| (isNewPricingOpen &&  isUpdatePricingOpen && addPricingReq.queueType ))
              &&(
                        ( addPricingReq.type == 'PERMANENT' && addPricingReq.weeklyRateNew && addPricingReq.weeklyRateUsed && addPricingReq.term && addPricingReq.startDate   )
                        ||(addPricingReq.type == 'TEMPORARY' && addPricingReq.endDate && addPricingReq.term && addPricingReq.startDate  && addPricingReq.weeklyRateNew && addPricingReq.weeklyRateUsed )
                        || (addPricingReq.type == 'MANUAL' && ((addPricingReq.weeklyRateNew && addPricingReq.weeklyRateUsed)|| addPricingReq.term)&& addPricingReq.manualPOTurns && addPricingReq.startDate )
                        ) && (addPricingReq.cashPriceFactor || addPricingReq.forcedCashPrice) ? false : true
                      }
            style={{ float: "right", marginRight: "2%", marginTop:'2%' }}
            onClick={() => {
              managePricing();
            }}
          >
            Next
          </RACButton>
          <RACButton
            variant="outlined"
            color="primary"
            size="small"
            style={{ float: "right", marginRight: "2%" , marginTop:'2%' }}
            onClick={() => {
              setAddPricingReq(addPricingRequestOject);
              setIsNewPricingOpen(!isNewPricingOpen);
              setIsUpdatePricingOpen(false);
              setZoneSelected([]);
              setRmsItemSelected("");
              // setSelectedItemsArray([]);
            }}
          >
            Cancel
          </RACButton>
          {isNewPricingOpen && isUpdatePricingOpen ? (
            <RACButton
              variant="text"
              color="primary"
              style={{ paddingRight: "1.25rem", marginTop:'3%' }}
              className={
                (JSON.stringify(formBackupData) == JSON.stringify(addPricingReq)) && formBackupData.type
                  ? classes.buttonDisabled
                  : ""
              }
              onClick={() => {
                console.log("Cleared", formBackupData, addPricingReq);
                setAddPricingReq(JSON.parse(JSON.stringify(formBackupData)));
              }}
            >
              Set as Default
            </RACButton>
          ) : null}
        </Grid>
      </>
    );
  };

  const updatingWarning = () => {
    return (
      <>
        <Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <DangerIcon />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                padding: "5%",
              }}
            >
              These records cannot be edited, Since you have selected the
              records of more than one type.
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };
  const approvalPopup = () => {
    return (
      <>
        <Grid>
          <Grid style={{ textAlign: "center", marginBottom: "2%" }}>
            <SuccessIcon />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{ textAlign: "center", marginBottom: "2%" }}
            >
              Request has been submitted.
            </Typography>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              Do you want to continue for approval?
            </Typography>
          </Grid>
          <Grid
            style={{ display: "flex", justifyContent: "center", margin: "5%" }}
          >
            <RACButton
              style={{ marginRight: "10%" }}
              variant="outlined"
              onClick={() => {
                setIsApprovalOpen(false);
              }}
            >
              No
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => {
                setIsApprovalOpen(false);
                setAuthOpen(true);
              }}
            >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  const someThingWentWrongFn = () => {
    return (
      <>
        <Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <WarningIcon width={"10%"} height={"10%"} />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                padding: "5%",
              }}
            >
              Something went Wrong.
            </Typography>
          </Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <RACButton
              onClick={() => {
                setSomeThingWentWrng(false);
              }}
              color="primary"
              variant="contained"
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Card
        style={{
          position: "fixed",
          bottom: "0px",
          width: "100%",
          zIndex: "1",
          left: "0",
        }}
      >
        <CardContent style={{padding:'1% important', float:'right',width:'100%'}}>
          <Grid container  style={{ float: "right",width:'100%',display:'inline' }}
              spacing={3}>
              <RACButton
                variant="contained"
                disabled={selectedItemsArray.length ? false : true}
                color="primary"
                size="small"
                style={{ float: "right", marginRight: "1%" }}
                onClick={() => {

                  {
                    if (
                      selectedItemsArray.every((item: any) => {
                        return (
                          item.pricingType == selectedItemsArray[0].pricingType
                        );
                      })
                    ) {
                      setIsUpdatePricingOpen(true);
                      setIsNewPricingOpen(true);
                    } else {
                      setIsUpdateWarningOpen(
                        true
                      ); /* Needed to include the warning popup set state */
                    }
                  }
                }}
              >
                Update
              </RACButton>
              <RACButton
                variant="contained"
                color="primary"
                size="small"
                style={{ float: "right", marginRight: "1%" }}
                onClick={() => {
                  setAddPricingReq(addPricingRequestOject);
                  setZoneSelected([]);
                  setIsNewPricingOpen(!isNewPricingOpen);
                }}
              >
                New Pricing
              </RACButton>
          </Grid>
          <Grid style={{ float: "right" }}></Grid>
        </CardContent>
      </Card>
      <Grid className={classes1.attachmentsPopUpStyles}>
        <RACModalCard
          isOpen={isAttachmentOpen >= 0 ? true : false}
          maxWidth="lg"
          borderRadius={10}
          onClose={() => {
            setIsAttachmentOpen(undefined);
          }}
          closeIcon={true}
          className={classes1.attachmentImageStyle}
          title="Product Image"
        >
          {attachmentsPopUp()}
        </RACModalCard>
      </Grid>
      <RACModalCard
        borderRadius={10}
        isOpen={isNewPricingOpen}
        maxWidth="md"
        onClose={() => {
          setAddPricingReq(JSON.parse(JSON.stringify(formBackupData)));
          setIsNewPricingOpen(!isNewPricingOpen);
          setIsUpdatePricingOpen(false);
        }}
        closeIcon={true}
        className={classes1.dialogBox}
        title={isUpdatePricingOpen ? "Update Pricing" : "New Pricing"} ///Validate
      >
        {pricingPopUp()}
      </RACModalCard>
      <RACModalCard
        isOpen={isUpdateWarningOpen}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => {
          setIsUpdateWarningOpen(false);
        }}
        borderRadius={10}
      >
        {updatingWarning()}
      </RACModalCard>
      <RACModalCard
        isOpen={isApprovalOpen}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setIsApprovalOpen(false)}
        borderRadius={10}
      >
        {approvalPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={someThingWentWrng}
        maxWidth="xs"
        borderRadius={10}
        onClose={() => setSomeThingWentWrng(false)}
      >
        {someThingWentWrongFn()}
      </RACModalCard>
    </>
  );
}
