/* eslint-disable */
import { RACCOLOR, makeStyles } from "@rentacenter/racstrap";
export const ProgressBar = () => {
  const useClasses = makeStyles((theme) => ({
    progress: {
      display: "flex",
      height: "0.7rem",
      overFlow: "hidden",
      fontSize: theme.typography.pxToRem(0.75),
      backgroundColor: "#e9ecef",
      borderRadius: "0.35rem ",
      width: "100px !important",
    },
    progressbar: {
      display: "flex",
    },
    infocss: {
      marginLeft: theme.typography.pxToRem(4),
      width: "12px",
    },
    currencyInputStylePromo: {
      "& div": {
        "& input": {
          height: "18px",
        },
        '& div': {
          '& div': {
            height: '32px',
            paddingRight: "0px",
            paddingLeft: "0px"
          }
        }
      },
      cursor: "pointer",
    },
    promoTextBoxBlue: {
      // position: "relative",
      // "&::after": {
      //   content: '""',
      //   position: "absolute",
      //   top: "0",
      //   bottom: "0",
      //   left: "0",
      //   right: "0",
      //   backgroundColor: "#ebebe4",
      //   opacity: "0.1",
      //   borderRadius: "6px",
      // },
    },
    progressBar: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      overFlow: "hidden",
      color: "#fff",
      textAlign: "center",
      whiteSpace: "nowrap",
      backgroundColor: "#e9ecef",
      transition: "width 0.6s ease",
      borderRadius: "0.35rem ",
    },
    bgCompleted: {
      backgroundColor: "#2CD19D !important",
    },
    bgFailed: {
      backgroundColor: "#E85C5C !important",
    },
    bgStriped: {
      backgroundImage:
        "linear-gradient(45deg, rgb(255 255 255 / 15%) 25%, #807e7e3b 25%, #ffffff00 50%, rgb(255 255 255 / 15%) 50%, rgb(255 255 255 / 15%) 75%, transparent 75%, #00000021)",
      backgroundSize: "1rem, 1rem",
    },

    progressBarLeftRadius0: {
      borderTopLeftRadius: "0 !important",
      borderBottomLeftRadius: "0 !important",
    },
    progressBarRightRadius0: {
      borderTopRightRadius: "0 !important",
      borderBottomRightRadius: "0 !important",
    },
    uploadBtnWrapper: {
      position: "relative",
      overflow: "hidden",
      display: "inline-block",
      cursor: "pointer",
      float: "right",
      "& input": {
        position: "absolute",
        left: 0,
        top: 0,
        opacity: 0,
        height: "32px",
        width: "100%",
      },
    },
    textSpaceNorecords: {
      textAlign: "center",
      width: "100%",
    },

    successPopup: {
      textAlign: "center",
      width: "100%",
      fontFamily: "OpenSans-bold",
      fontSize: theme.typography.pxToRem(14),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    textcenterNorecords: {
      textAlign: "center",
      marginTop: theme.typography.pxToRem(60),
      width: "100%",
      fontFamily: "OpenSans-regular",
      fontSize: theme.typography.pxToRem(14),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    racErrorIcon: {
      width: "50px",
    },
    textcenterImage: {
      textAlign: "center",
      marginTop: theme.typography.pxToRem(20),
      width: "100%",
      fontFamily: "OpenSans-bold",
      fontSize: theme.typography.pxToRem(16),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    loader: {
      textAlign: "center",
      // position: 'absolute',
      // top: '45%',
      // left: '50%',
      // transform: 'translate(-50%, 85%)',
    },
    masterLoader: {
      position: "fixed",
      backgroundColor: `${RACCOLOR.WASHED_BLACK}`,
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      opacity: 0.6,
      zIndex: 1000,
      textAlign: "center",
      margin: 0,
    },
    Loader: {
      display: "block",
      position: "fixed",
      zIndex: 1051,
      top: "50%",
      right: "50%",
    },
    container: {
      margin: "1rem",
    },

    items: {
      marginbottom: "1rem",
    },
    cursorType: {
      cursor: "pointer",
    },
    validerroralign: {
      marginTop: "7px",
      paddingLeft: "2px",
    },
    pagination: {
      display: "flex",
      listStyle: "none",
      textDecoration: "none",
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(14),
      justifyContent: "flex-end",
      cursor: "pointer",
    },
    paginationprevnext: {
      color: "#000",
      padding: "5px 17px",
      display: "inline-block",
    },
    pageactive: {
      backgroundColor: "#2468FF",
      color: "#fff",
      borderRadius: "50%",
    },
    paginationprevnextdisable: {
      color: "lightgray !important",
      padding: "5px 17px",
      display: "inline-block",
    },
    pagelink: {
      position: "relative",
      display: "block",
      textDecoration: "none",
      padding: "5px 0px",
      width: "29px",
      textAlign: "center",
    },
    totrec: {
      float: "left",
    },
    pleasewaittextcenter: {
      textAlign: "center",
      marginTop: theme.typography.pxToRem(5),
      width: "100%",
      fontSize: theme.typography.pxToRem(15),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    newcircularprogress: {
      width: "1.875rem !important",
      height: "1.938rem !important",
    },
    animatedLoader: {
      width: "40px",
      aspectRatio: "1",
      borderRadius: "50%",
      border: "4px solid #0d6dfc",
      borderRightColor: "white",
      animation: `$s2 1s infinite linear`,
    },
    "@keyframes s2": {
      to: {
        transform: "rotate(1turn)",
      },
    },
    buttonDisabled: {
      "& span": {
        color: "#BED7FF !important",
        fontSize: "0.8125rem",
        textDecoration: "underline",
      },
    },
    currencyTextBoxBlue: {
      position: "relative",
      "&::after": {
        content: '""',
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "-30px",
        right: "0",
        backgroundColor: "#2468FF",
        opacity: "0.1",
        borderRadius: "6px",
      },
    },
    currencyInputStyle: {
      paddingBottom:'1px !important',
      "& div": {
        "& input": {
          height: "21px",
        },
      },
      cursor: "pointer",
    },
    textcenterLoader: {
      textAlign: "center",
      marginTop: theme.typography.pxToRem(170),
      width: "100%",
      fontFamily: "OpenSans-regular",
      fontSize: theme.typography.pxToRem(14),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    cusStyleDisableColor: {
      '& div': {
        '& div': {
          '& div': {
            '& input': {
              backgroundColor: 'rgb(235, 235, 228) !important'
            }
          }

        }
      }
    },
    txtAreaStyleDisableColor: {
      '& textarea': {
        backgroundColor: 'rgb(235, 235, 228) !important'
      }
    },
    datePickerStyles: {
      '& div': {
        '& div': {
          '& label':{
            fontFamily: "OpenSans-bold !important"
          },
          '& div': {
            '& input': {
              fontFamily: "OpenSans-semibold"
            }
          }

        }
      }
    },
    multiSelectStyles: {
      '& div': {
        '& div': {
          '& div': {
            '& div': {
              fontFamily: "OpenSans-semibold",
              '& span': {
                color: "#353535 !important",
                display:"block",
                width:"400px"
              }
            }
          }
        }
      }
    },
    dropDownStyles: {
      '& div': {
        '& div': {
          '& label':{
            fontFamily: "OpenSans-bold !important"
          },
          '& div': {
            fontFamily: "OpenSans-semibold"
          }
        }
      }
    },

    overrideDisbaleInCurrencyInp: {
      paddingBottom:'1px !important',
      "& div": {
        "& input": {
          height: "21px",
          backgroundColor:'#EFF4FA !important'
        },
      },
      cursor: "pointer",
    },
    multiSelect: {
      "& div": {
        height: "34px",
        paddingBottom:'1px !important',
      },
      "& div": {
        "& span": {
          display: "flex",
          alignItems: "center",
        },
      },
      "& .dropdown-container": {
        height: "36px",
      },
    },

    currencyInputStylePackage: {
      paddingBottom:'1px !important',
      "& div": {
        "& input": {
          height: "18px",
        },
        height: "33px",
      },
      cursor: "pointer",
    },
    packageTextBox: {
      position: "relative",
      "&::after": {
        content: '""',
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "-30px",
        right: "0",
        opacity: "0.1",
        borderRadius: "6px",
      },

        width:'86% !important',
      
    },
    racSelect: {
      paddingBottom:'1px !important',
      "& div": {
        "& div": {
          "& div": {},
          "& div": {
            height: "21px",
            "& svg": {
              height: "40px",
            },
          },
        },
      },
    },
  }));
  const classes = useClasses();
  return classes;
};
