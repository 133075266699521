/* eslint-disable */
import React, { createContext, useEffect, useState } from "react";
import updatePricingQueueStatusAPI, { fetchPricingDetailsAPI } from "../../../api/user";
export const PricingDetailsProvider = createContext<any>("");
export default function PricingContext(props: any) {
  const [disableFilter, setDisableFilter] = useState<any>(); //instead of it use searchBtnTrigger
  const filterObj = {
    rmsItemNumber: null,
    brandName: null,
    modelNumber: null,
    departmentNumber: null,
    subdepartmentNumber: null,
    zoneNumber: [],
    bracketName: null,
    type: "",
    category: "",
    offset: "0",
    limit: "25",
  };

  const [type, setType] = useState([
    { label: "Select", value: "" },
    { label: "MANUAL", value: "MANUAL" },
    { label: "PERMANENT", value: "PERMANENT" },
    { label: "TEMPORARY", value: "TEMPORARY" },
  ]);
  const [category, setCategory] = useState([
    { label: "Select", value: "" },
    { label: "Extended Aisle", value: "EA" },
  ]);
  const[companyCode,setCompanyCode] = useState([]);
  const [isFranchiseeUser, setIsFranchiseeUser] = useState(false);
  const [loader, setLoader] = useState<any>(false);
  const [isSLACompleted, setIsSLACompleted]= useState(false);
  const [isSLACancel,setIsSLACancel] = useState(false); 
  const [filterOptions, setFilterOptions] = useState<any>(filterObj);
  const [searchBtnTrigger, setSearchBtnTrigger] = useState<any>();
  const [selectedItemsArray, setSelectedItemsArray] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [isFilterOpen, setIsFilterOpen] = useState(true); //To set the filter open close
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(); // Which takes the index of the row clicked as a value
  const [isNewPricingOpen, setIsNewPricingOpen] = useState(false); // used to close/ open pricing popup add isNEw
  const [pricingQueueId, setPricingQueueId] = useState<string>();
  const [isUpdatePricingOpen, setIsUpdatePricingOpen] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [isApproved, setIsApproved] = useState(false);
  const [emailId,setEmailId]= useState();
  const [employeeId, setEmployeeId]= useState();

  let addPricingRequestOject = {
    pricingType: "",
    weeklyRateNew: "",
    weeklyRateUsed: "",
    term: "",
    cashPriceFactor: "",
    forcedCashPrice: "",
    cashPrice: "",
    totalCost: "",
    manualPoTurns: "",
    startDate: "",
    endDate: "",
    cashPriceNew: "",
    totalCostNew: "",
    cashPriceUsed: "",
    totalCostUsed: "",
    queueType: "",
    clearanceFlag: "",
    rmsItemDetails: [
      {
        zone: "",
        rmsItemNumber: [""],
      },
    ],
  };
  const [noRecords, setNoRecords] = useState(false);
  const [formBackupData, setFormBackupData] = useState(JSON.parse(JSON.stringify(addPricingRequestOject))); // used for textboxes in the popup backup data
  const [addPricingReq, setAddPricingReq] = useState(JSON.parse(JSON.stringify(addPricingRequestOject))); // used for textboxes in the popup actual data
  const [isRecUpdated, setIsRecUpdated] = useState(false);
  const [zoneOptions, setZoneOptions] = useState<any>([]);
  const [pricingGridData, setPricingGridData] = useState<any>();
  const pricingTable = [
    {
      heading: "",
      isSortable: "true",
      sortDirection: "DESC",
      key: "zone",
    },
    {
      heading: "Zone",
      isSortable: true,
      sortDirection: "DESC",
      key: "zoneNumber",
      isSorted: true,
    },
    {
      heading: "RMS Number",
      isSortable: true,
      sortDirection: "DESC",
      key: "rmsItemNumber",
      isSorted: true,
    },
    {
      heading: "Description",
      isSortable: false,
      sortDirection: "DESC",
      key: "rmsItemDescription",
      isSorted: false,
    },
    {
      heading: "Department",
      isSortable: true,
      sortDirection: "DESC",
      key: "departmentName",
      isSorted: true,
    },
    {
      heading: "Sub-Department",
      isSortable: true,
      sortDirection: "DESC",
      key: "subdepartmentName",
      isSorted: true,
    },
    {
      heading: "Bracket",
      isSortable: false,
      sortDirection: "DESC",
      key: "bracketName",
      isSorted: false,
    },
    {
      heading: "Brand",
      isSortable: false,
      sortDirection: "DESC",
      key: "brandName",
      isSorted: false,
    },
    {
      heading: "Model #",
      isSortable: false,
      sortDirection: "DESC",
      key: "modelNumber",
      isSorted: false,
    },
    {
      heading: "Type",
      isSortable: false,
      sortDirection: "DESC",
      key: "pricingType",
      isSorted: false,
    },
    {
      heading: "Purchase Cost",
      isSortable: false,
      sortDirection: "DESC",
      key: "purchaseCost",
      isSorted: false,
    },
    {
      heading: "Weekly Rate New",
      isSortable: false,
      sortDirection: "DESC",
      key: "weeklyRateNew",
      isSorted: false,
    },
    {
      heading: "Weekly Rate Used",
      isSortable: false,
      sortDirection: "DESC",
      key: "weeklyRateUsed",
      isSorted: false,
    },
    {
      heading: "Cash Price (NEW)",
      isSortable: false,
      sortDirection: "DESC",
      key: "cashPriceNew",
      isSorted: false,
    },
    {
      heading: "Cash Price Turns (NEW)",
      isSortable: false,
      sortDirection: "DESC",
      key: "cashPriceTurn",
      isSorted: false,
    },
    {
      heading: "Total Cost (NEW)",
      isSortable: false,
      sortDirection: "DESC",
      key: "totalCostNew",
      isSorted: false,
    },
    {
      heading: "TRTO Turns (NEW)",
      isSortable: false,
      sortDirection: "DESC",
      key: "totalRtoTurn",
      isSorted: false,
    },
    {
      heading: "Cash Price (USED)",
      isSortable: false,
      sortDirection: "DESC",
      key: "cashPriceUsed",
      isSorted: false,
    },
    {
      heading: "Total Cost (USED)",
      isSortable: false,
      sortDirection: "DESC",
      key: "totalCostUsed",
      isSorted: false,
    },
    {
      heading: "Term",
      isSortable: false,
      sortDirection: "DESC",
      key: "term",
      isSorted: false,
    },
    {
      heading: "Cash Price Factor",
      isSortable: false,
      sortDirection: "DESC",
      key: "cashPriceFactor",
      isSorted: false,
    },
    {
      heading: "Forced Cash Price",
      isSortable: false,
      sortDirection: "DESC",
      key: "forcedCashPrice",
      isSorted: false,
    },
    {
      heading: "Manual Po-Turns",
      isSortable: false,
      sortDirection: "DESC",
      key: "manualPoTurns",
      isSorted: false,
    },
    {
      heading: "Start Date",
      isSortable: true,
      sortDirection: "DESC",
      key: "startDate",
      isSorted: true,
    },
    {
      heading: "End Date",
      isSortable: true,
      sortDirection: "DESC",
      key: "endDate",
      isSorted: true,
    },
    {
      heading: "Attachment",
      isSortable: false,
      sortDirection: "DESC",
      key: "attachment",
      isSorted: false,
    },
  ];
  const [someThingWentWrng, setSomeThingWentWrng] = useState(false);
  const [rmsItemDetailsArr, setRmsItemDetailsArr] = useState([]);
  const [pricingHeader, setPricingHeader] = useState(pricingTable);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [isApprover, setIsApprover]= useState(false);
  const [isApprovalOpen, setIsApprovalOpen] = useState(false);

  const[authOpen, setAuthOpen] = useState(false);
  const [fullloader,setFullloader] = useState(false);
  const [approvedBy,setApprovedBy] = useState();
  {
    console.log(noRecords, "The records found");
  }
  console.log(pricingTable.length, "the length of the pricing array");
  const fetchpricingGridData = async (type: any = "", index: any = 0) => {
    let arr = pricingGridData ? Object?.keys(pricingGridData) : undefined;
    setLoader(true);
    let pricingRequest = JSON.parse(JSON.stringify(filterOptions));
    pricingRequest.offset = `${index + 1}`;
    pricingRequest.category =
      pricingRequest.category === "" ? undefined : pricingRequest.category;
    pricingRequest.type =
      pricingRequest.type === "" ? undefined : pricingRequest.type;
      pricingRequest.zoneNumber =  pricingRequest.zoneNumber.length == zoneOptions.length ? undefined:  pricingRequest.zoneNumber;
      pricingRequest.companyCode = companyCode.length ? companyCode : undefined;
    try {
      let arr1=Object.keys(pricingRequest)
      arr1.forEach((item : any)=>{if(!pricingRequest[item] && item !='type' && item !='category'&& item !='zoneNumber' )pricingRequest[item]=null})
      if (arr?.length && type !== "A") {
        if (arr?.includes(`${index}`)) {
          console.log(pricingRequest);
          setCurrentTableData(pricingGridData[index]);
        } else {
          setTotalRowCount(0);
          const resp = await fetchPricingDetailsAPI(pricingRequest);
          handleApiResponse(resp, index);
        }
      } else {
        setTotalRowCount(0);
        const resp = await fetchPricingDetailsAPI(pricingRequest);
        handleApiResponse(resp, index);
        setPageCount(Math.ceil(resp.data.totalRecordsCount / 25));
      }
    } catch (err) {
      console.log(err, "the error in 724");
      setSomeThingWentWrng(true);
    } finally {
      setLoader(false);
    }
  };
  const handleApiResponse = (resp: any, index: any) => {
    console.log(resp, "The rep");
    if (resp.status === 200) {
      if (resp.data?.pricingDetails?.length) {
        setIsFilterOpen(false);
        setTotalRowCount(resp.data.totalRecordsCount);
        setCurrentTableData(resp.data.pricingDetails);
        setPricingGridData({
          ...pricingGridData,
          [`${index}`]: resp.data.pricingDetails,
        });
      } else {
        setNoRecords(true);
      }
      console.log(resp.data.pricingDetails, "The response from the pricing1");
    } else {
      throw "Err";
    }
  };
  const updatePricingQueueStatus = async (params: any) => {
    try {
      setFullloader(true);
      let req = {
        pricingQueueId: pricingQueueId?.toString(),
        status: params,
        actionBy: approvedBy,
      };
      const response = await updatePricingQueueStatusAPI(req);
      if (response.status == 200) {
        setFullloader(false);
        setSelectedItemsArray([]);
        setAuthOpen(false);
        setIsApproved(true);
        // await fetchpricingGridData('A');
      } else {
        setSomeThingWentWrng(true);
        setFullloader(false);
      }
    } catch (e) {
      setSomeThingWentWrng(true);
      setFullloader(false);
    }
  };
  return (
    <PricingDetailsProvider.Provider
      value={{
        pricingHeader,
        someThingWentWrng,
        setSomeThingWentWrng,
        setPricingHeader,
        fetchpricingGridData,
        currentTableData,
        setCurrentTableData,
        disableFilter,
        addPricingRequestOject,authOpen, setAuthOpen,
        isSLACancel,setIsSLACancel,approvedBy,setApprovedBy,
        addPricingReq,
        setAddPricingReq,
        setDisableFilter,
        formBackupData,
        setFormBackupData,
        rmsItemDetailsArr,
        setRmsItemDetailsArr,
        isAttachmentOpen,
        isRecUpdated,
        setIsRecUpdated,
        setIsAttachmentOpen,updatePricingQueueStatus,
        pricingGridData,
        setNoRecords,
        noRecords,companyCode,setCompanyCode,
        setPricingGridData,
        filterOptions,
        setFilterOptions,
        filterObj,
        isFilterOpen,
        setIsFilterOpen,
        searchBtnTrigger,
        setSearchBtnTrigger,
        type,
        setType,
        category,
        setCategory,
        loader,
        setLoader,
        isNewPricingOpen,
        pageCount,
        setPageCount,
        setIsNewPricingOpen,
        pricingTable,
        totalRowCount,
        setTotalRowCount,
        selectedItemsArray,setIsApprover,
        setSelectedItemsArray,
        pageNumber,
        setPageNumber,
        pricingQueueId,
        setPricingQueueId,
        zoneOptions,isApproved, setIsApproved,
        setZoneOptions,isApprovalOpen, setIsApprovalOpen,
        isUpdatePricingOpen,fullloader,setFullloader,isFranchiseeUser, setIsFranchiseeUser,
        setIsUpdatePricingOpen,employeeId, setEmployeeId,emailId,setEmailId,
        isApprover,isSLACompleted, setIsSLACompleted,
      }}
    >
      {props.children}
    </PricingDetailsProvider.Provider>
  );
}
