/* eslint-disable */
import { useEffect, useState } from "react"
import { validateApprover } from "./getEmployeeInfoAndReferenceData"
import { globalStyles } from "../Pricing/Styles/globalstyles";
import { RACModalCard,Grid,RACButton, Typography,  makeStyles,RACTextbox,CircularProgress,RACCOLOR } from "@rentacenter/racstrap"
import PinField from 'react-pin-input';
import clsx from 'clsx';
import { GetMenuAndStoredbasedOnProfileAPI, GetRemoteLoginStatus, getCurrentInfo, getPinDetails, getsecondfactor } from "../../api/user";
import { ReactComponent as RACLogo } from '../../assets/images/RACNewLogo.svg'



export default function TwoFactorAuthentication(props : any){
const {isVisible,IsHigherAuthority,setIsVisible, loader, submitedBy,setloader,setTwoFactorCompleted,mailId,ApprovedBy,employeeId,setTwoFactorCancelClick,twoFactorCompleted,twoFactorCancelClick,setSomethingWentWrong} = props;
const classes = globalStyles();
let [otp, setotp] = useState('');
const [openModal, setOpenModal] = useState(false);
const [thresHoldExceptionMessage, setthresHoldExceptionMessage] =
  useState('');
const [openRemotePopup, setopenRemotePopup] = useState(false);
const [remoteId, setremoteId] = useState('');
const [securityCode, setsecurityCode] = useState('');
const [errorMessage, seterrorMessage] = useState('');
// const progressclasses = ProgressBar();
const [openSecondModal, setOpenSecondModal] = useState(false);
const [userID, setUserID] = useState('');
const [password, setPassword] = useState('');
const [errorline, setErrorLine] = useState(false);
const [roleCapture, setroleCapture] = useState('');
const [openPinModal, setOpenPinModal] = useState(false);
const [remoteStatement, setRemoteStatement]= useState("Please enter the approver's Remote ID and Security Code to continue")
// eslint-disable-next-line prefer-const
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const [InvalidPin, setInvalidPin] = useState<any>();
//   const [InvalidPin1, setInvalidPin1] = React.useState(false);
const [Continue, setContinue] = useState(true);

useEffect(()=>{
    console.log(isVisible,'two factor authentication',IsHigherAuthority);
    debugger;
   if(IsHigherAuthority && isVisible)
   {
    setOpenPinModal(true);
   }
   else if(!IsHigherAuthority && isVisible){
    setOpenModal(true);
   }
},[IsHigherAuthority, isVisible]);
useEffect(() => {
    if (otp.length == 4) {
      setContinue(false);
    } else {
      setContinue(true);
    }
  }, [otp]);

const pinEnter = (event: any) => {
    // eslint-disable-next-line sonarjs/no-redundant-boolean
    if (event.charCode === 13 && Continue == false) {
      event.preventDefault();
      continueClick();
    }
  };
  const continueClick = async () => {
    setOpenPinModal(false);
    debugger;
    let id;
    setloader(true);
    if(mailId|| employeeId){
        id = employeeId ? employeeId : mailId
    }
    else{
    const userResponse = await getCurrentInfo();
     id = userResponse.data.employeeId
      ? userResponse.data.employeeId
      : userResponse.data.email.toLowerCase();
    }
    // setEmployeeDetails !== undefined
    //   ? setEmployeeDetails(userResponse.data)
    //   : null;
    const pin = otp;
    const payload = {
      userId: id,
      encryptedPin: pin.toString(),
    };
    const result = await getPinDetails(payload);
    setloader(false);
    setOpenPinModal(true);
    setInvalidPin(result.status);
    ApprovedBy(submitedBy)
    if (result.data.statusCode == 200 ) {
      setOpenPinModal(false);
      !twoFactorCompleted ? setTwoFactorCompleted(true) : null;
      setTwoFactorCancelClick(false);
    }
  };
  const pinValue = (event: any) => {
    otp = event;
  };
  const otpchange = (event: any) => {
    setInvalidPin(200);
    setotp(event);
  };
  const noClick = () => {

      setOpenPinModal(false);
      setTwoFactorCompleted(false);
      setTwoFactorCancelClick(true);
      setIsVisible(false);
  };
//   const onPinCloseClick = () => {

//     setOpenPinModal(false);
//     setTwoFactorCompleted(false);
//     setTwoFactorCancelClick(true);

// };
const openPinModalContent = () => {
    return (
      <div>
        <Grid container className={classes.racPinContainer}>
          <Grid item md={12}>
            <Typography className={classes.racPinLabel}>PIN</Typography>
          </Grid>
          <Grid item md={12} onKeyPress={pinEnter}>
            <PinField
              length={4}
              type="numeric"
              focus={true}
              secret={true}
              autoSelect={false}
              style={{ marginLeft: '0.3rem' }}
              inputStyle={{
                border: '1px solid #adb5bd',
                borderRadius: '0.6rem',
                marginRight: '20px',
              }}
              onComplete={(event:any) => pinValue(event)}
              onChange={(event:any) => otpchange(event)}
            />
          </Grid>
          {InvalidPin == 400 ? (
            <Grid>
              <Typography id="InvalidPin" className={classes.racPinError}>
                Invalid PIN{' '}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          classes={{
            root: clsx(
              classes.floatRight,
              classes.spacerMT5,
              classes.spacerMB3,
              classes.paddingButtons
            ),
          }}
          style={{paddingRight:'70px'}}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.spacerME2}
            onClick={noClick}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            disabled={Continue}
            onClick={continueClick}
          >
            Continue
          </RACButton>
        </Grid>
      </div>
    );
  };
  const handleUserID = (event: any) => {
    // setErrorLine(false);
    setUserID(event.target.value);
    setroleCapture(event.target.value);
  };

  const handlePassword = (event: any) => {
    // setErrorLine(false);
    setPassword(event.target.value);
  };

  const handleRemoteID = (event: any) => {
    setremoteId(event.target.value);
  };

  const handleSecurityCode = (event: any) => {
    setsecurityCode(event.target.value);
  };

  const remoteContinue = async (remoteId: any, securityCode: any) => {
    setopenRemotePopup(false);
    setloader(true);
    const payload = {
      userName: remoteId,
      passCode: securityCode,
     validateDuoAuth: true
    };
    seterrorMessage('');
    const result = await GetRemoteLoginStatus(payload);
    RemoteValidate(result);
  };
  const secondfactorEnter = (event: any, username: any, Password: any) => {
    if (event.charCode === 13 && userID !== '' && password !== '') {
      event.preventDefault();
      loginClick(username, Password);
    }
  };
  const[isNotApprover,setIsNotApprover]= useState(false);
  const UserValidate = async(result: any) => {
    debugger
    setPassword('');
    setIsVisible(false);
    // setUserID('');
    console.log(result,"the result in the two factor");
    try{
    if (result.status== 200) {
      if (
        result.data.LoginAuthenticationResult.value.StatusCode === '200' 
      ) {
        let req = {
            coworkerId: result.data.CoworkerAuthenticationResult.value.employeeId , //'7095001' 
            menuRequired:  true ,
            companyCodeRequired: false,
            "menuRequest": {
              "association": {
                  "associationCode": window.sessionStorage.getItem('storeNumber'), //Store from session
                  "associationType": "STORE"
              }
          }
          };
          let res = await GetMenuAndStoredbasedOnProfileAPI(req)
          setloader(false);

          if(res.status==200){

            if(validateApprover(res.data.menuConfig)){
        setOpenModal(false);
        ApprovedBy(String(res.data.coworkerProfile.firstName + ' '  +res.data.coworkerProfile.lastName))
        !twoFactorCompleted
          ? setTwoFactorCompleted(true)
          : null;
        setTwoFactorCancelClick(false);
          }
          else{
            setIsVisible(true);
            setErrorLine(false);
            setIsNotApprover(true);
            setOpenModal(true);
           
          }
        }
        else{
            setSomethingWentWrong(true);

        }
      } else {
        setOpenModal(false);
      }
    } else if (
      result.status == 400 
    ) {
      setIsVisible(true);
      setIsNotApprover(false);
      setOpenModal(true);
      setErrorLine(true);
      
        // setSomethingWentWrong(true)
        // setOpenModal(false);
        // setIsVisible(false)
    } else {
      setOpenModal(false);
      setSomethingWentWrong(true);
      setIsVisible(false);
    }
    setUserID('');
}catch(e : any){
  setOpenModal(false);
  setSomethingWentWrong(true);
  setIsVisible(false);
  setloader(false);
  setUserID('');
  setTwoFactorCompleted(false)
  setTwoFactorCancelClick(false);
}
  };
  const loginClick = async (username: any, Password: any) => {
    console.log('username,password', username, Password);
    //setRoleFromSLAModule(username);
    setErrorLine(false);
    setIsNotApprover(false);
    setOpenModal(false);
    setloader(true);
    const payload = {
      userName: username,
      role: window.localStorage.getItem('currentRole'),
        password: Password,
    };  
    console.log('SLApayload', payload);
    setErrorLine(false);
    const result = await getsecondfactor(payload);
    console.log('SLA result', result);
    UserValidate(result);
  };

  const openModalContent = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>
          <RACLogo className={classes.logoResponsive}></RACLogo>
        </Grid>
        {errorline === true ? (
          <Grid className={classes.spacerMargin}>
            <Typography className={classes.racLoginAlert}>
              The user ID and/or password you entered are not valid. Please try
              again.
            </Typography>
          </Grid>
        ) : null}
        {isNotApprover === true ? (
          <Grid className={classes.spacerMargin}>
            <Typography className={classes.racLoginAlert}>
            User doesn't have authorization to approve
            </Typography>
          </Grid>
        ) : null}
        <Grid container spacing={2} className={classes.spacerMT1}>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              User ID
            </Typography>
            <RACTextbox
              maxlength={150}
              isCurrency={false}
              type="text"
              value={userID}
              OnChange={(event) => handleUserID(event)}
            ></RACTextbox>
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              Password
            </Typography>
            <RACTextbox
              maxlength={15}
              onKeyPress={(event) => secondfactorEnter(event, userID, password)}
              isCurrency={false}
              type="password"
              value={password}
              OnChange={(event) => handlePassword(event)}
            ></RACTextbox>
          </Grid>
        </Grid>
        <Grid
          classes={{
            root: clsx(
              classes.floatRight,
              classes.spacerMT5,
              classes.spacerMB3,
              classes.buttonMargin
            ),
          }}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.spacerME2}
            onClick={openModelCancelClick}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            className={classes.spacerME2}
            onClick={() => remoteClick()}
          >
            Remote
          </RACButton>

          {userID !== '' && password !== '' ? (
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => loginClick(userID, password)}
            >
              Login
            </RACButton>
          ) : (
            <RACButton
              variant="contained"
              color="primary"
              className={classes.disabled}
            >
              Login
            </RACButton>
          )}
        </Grid>
      </div>
    );
  };
  const onCloseSecondfacClick = () => {
      setOpenModal(false);
      setTwoFactorCompleted(false);
      setTwoFactorCancelClick(true);
  };
  const remoteModelCancelClick = () => {
    setRemoteStatement("Please enter the approver's Remote ID and Security Code to continue")
      seterrorMessage('');
      setopenRemotePopup(false);
      setTwoFactorCancelClick !== undefined
        ? setTwoFactorCancelClick(true)
        : null;
        setIsVisible(false);
  };
  const openRemoteModal = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>
          <RACLogo className={classes.logoResponsive}></RACLogo>
        </Grid>
        {errorMessage !== '' ? (
          <Grid className={classes.spacerMargin}>
            <Typography className={classes.racRemoteLoginAlert}>
              {errorMessage}
            </Typography>
          </Grid>
        ) : null}
        <Grid className={classes.spacerMargin}>
          <Typography className={classes.racRemoteLogin}>
            {remoteStatement}
          </Typography>
        </Grid>
        <Grid container spacing={2} className={classes.spacerMT1}>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              Remote ID
            </Typography>
            <RACTextbox
              maxlength={30}
              isCurrency={false}
              type="text"
              value={remoteId}
              OnChange={(event) => handleRemoteID(event)}
            ></RACTextbox>
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              Security Code
            </Typography>
            <RACTextbox
              maxlength={15}
              onKeyPress={(event) => remoteEnter(event, remoteId, securityCode)}
              isCurrency={false}
              type="password"
              value={securityCode}
              OnChange={(event) => handleSecurityCode(event)}
            ></RACTextbox>
          </Grid>
        </Grid>
        <Grid
          classes={{
            root: clsx(
              classes.floatRight,
              classes.spacerMT5,
              classes.spacerMB3
            ),
          }}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.spacerME2}
            onClick={remoteModelCancelClick}
          >
            Cancel
          </RACButton>
          {remoteId !== '' && securityCode !== '' ? (
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => remoteContinue(remoteId, securityCode)}
            >
              Continue
            </RACButton>
          ) : (
            <RACButton
              variant="contained"
              color="primary"
              className={classes.disabled}
            >
              Continue
            </RACButton>
          )}
        </Grid>
      </div>
    );
  };

  
   
  const RemoteValidate =async (result: any) => {
    try{
    if (result?.status == 200) {
      if (result?.data?.response?.result == 'allow') {
        setopenRemotePopup(false);
        setsecurityCode('');
        // setTwoFactorCompleted !== undefined
        //   ? setTwoFactorCompleted(true)
        //   : null;
        let req = {
          coworkerId: remoteId, //'7095001' 
          menuRequired:  true ,
          companyCodeRequired: false,
          "menuRequest": {
            "association": {
                "associationCode": window.sessionStorage.getItem('storeNumber'), //Store from session
                "associationType": "STORE"
            }
        }
        };
        let res = await GetMenuAndStoredbasedOnProfileAPI(req)
        setremoteId('');
        setloader(false);

        if(res.status==200){

          if(validateApprover(res.data.menuConfig)){
            setopenRemotePopup(false);
            ApprovedBy(String(res.data.coworkerProfile.firstName + ' '  +res.data.coworkerProfile.lastName))
      !twoFactorCompleted
        ? setTwoFactorCompleted(true)
        : null;
      setTwoFactorCancelClick(false);
        }
        else{
          setIsVisible(true);
          // setIsNotApprover(true);
          seterrorMessage('Authentication failed');
          setRemoteStatement('User not authorized to approve')
        setremoteId('');
        setsecurityCode('');
        setopenRemotePopup(true);
         
        }
      }

      } else {
        seterrorMessage(result?.data?.response?.status_msg);
        setremoteId('');
        setsecurityCode('');
        setopenRemotePopup(true);
        setRemoteStatement('Please enter the valid Remote ID and Security Code')
      }
    } else {
      console.log('Hi from remote validate', result);
      setremoteId('');
      setsecurityCode('');
      seterrorMessage(result?.data?.errors[0]?.error?.split('"')[7]);
      setopenRemotePopup(true);
      setRemoteStatement('Please enter the valid Remote ID and Security Code')

    }
  }
  catch(e: any){
    setremoteId('');
    setsecurityCode('');
    setTwoFactorCompleted(false);
    setTwoFactorCancelClick(false);
    setopenRemotePopup(false);
    setIsVisible(false);
    setSomethingWentWrong(true);
    setloader(false);

  }

  };
  const remoteClick = () => {
    setOpenModal(false);
    setopenRemotePopup(true);
  };

  const remoteEnter = (event: any, remoteId: any, securityCode: any) => {
    if (event.charCode === 13 && remoteId !== '' && securityCode !== '') {
      event.preventDefault();
      remoteContinue(remoteId, securityCode);
    }
  };
  const openModelCancelClick = () => {

      setErrorLine(false);
      setOpenModal(false);
      setUserID('');
      setIsNotApprover(false)
      setPassword('')
      setTwoFactorCompleted(false);
      setTwoFactorCancelClick(true);
      setIsVisible(false);
  };

return (
    
        isVisible ?
        <>
        <RACModalCard
         isOpen={openPinModal}
         borderRadius="20px"
         className="racPinPopup"
         closeIcon={true}
         onClose={noClick}
         TitleClassName={classes.cutomTitlePinPopup}
         maxWidth={'xs'}
         title={'Enter PIN to continue'}
         //eslint-disable-next-line react/no-children-prop
         children={openPinModalContent()}
       ></RACModalCard>
              <RACModalCard
         isOpen={openModal}
         borderRadius="20px"
         onClose={onCloseSecondfacClick}
         maxWidth={'xs'}
         className="racSecondFacPopup"
         title={''}
         //eslint-disable-next-line react/no-children-prop
         children={openModalContent()}
       ></RACModalCard>
       <RACModalCard
         isOpen={openRemotePopup}
         borderRadius="20px"
         maxWidth={'xs'}
         className="racSecondFacPopup"
         title={''}
         //eslint-disable-next-line react/no-children-prop
         children={openRemoteModal()}
       ></RACModalCard>
        </>
        :
        <>
        </>
    
)
}