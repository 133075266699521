/* eslint-disable */
import {
  Typography,
  Grid,
  RACButton,
  CardContent,
  Card,
  RACSelect,
  RACDatePicker,
  RACTableCell,
  RACRadio,CircularProgress,
  RACTable,
  RACTableRow,
  RACModalCard,
  RACCheckBox,
} from "@rentacenter/racstrap";
import { ReactComponent as RacFilter } from "../../../assets/images/filter-white.svg";
import { ReactComponent as AccordianOpen } from "../../../assets/images/up-arrow-filter.svg";
import { ReactComponent as AccordianClose } from "../../../assets/images/down-arrow-filter.svg";
import React, { useContext, useState } from "react";
import { ReactComponent as FilterClose } from "../../../assets/images/filter-close-icon.svg";
import moment from "moment";
import { globalStyles } from "../Styles/globalstyles";

import { ReactComponent as SuccessIcon } from "../../../assets/images/danger.svg";
import { PricingQueueContext } from "./PricingQueueContext";
import updatePricingQueueStatusAPI from "../../../api/user";
import { ProgressBar } from "../Styles/ProgressStyles";

export default function PricingQueueFilter() {
  const {
    schedule,
    filterReq,isRejected,
    setFilterReq,setIsRejected,isApprover, 
    setSchedule,fullLoader,setFullLoader,
    queueType,
    setQueueType,
    setLoader,
    queueStatusType,setPageNumber,
    setQueueStatusType,comments, setComments,
    fetchPricingQueueDetail,setIsApproved,isapprovalPopup, setIsapprovalPopup,
    isFranchiseeUser,
    companyCode,
    approvalPopup,
    alterPopUp,
    setAlterPopUp,
    setApprovalPopup,
    indexValue,isAlterOpen,setIsAlterOpen,
    setIndexValue,
    zoneDetails,
    setSomeThingWentWrng,
    setZoneDetails,setTotalRowCount,
    tableBody,
  } = useContext(PricingQueueContext);
  const progressclasses = ProgressBar();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [popup, setPopup] = useState("");
  const classes = globalStyles();
  const openFilterpopup = () => {
    //To disable the future dates in RACdate Picker Using the us timezone
    const dt = new Date();
    const time = dt.toLocaleString("en-US", { timeZone: "America/Chicago" });
    const current = time.split(",")[0];
    console.log("current", moment(current).format("YYYY-MM-DD"));
    const maxdt = moment(current).format("YYYY-MM-DD");
    console.log("maxdt", maxdt);
    return (
      <Card
        className={`${classes.positionAbsolute} ${classes.filterPricingQueueDropDown}`}
      >
        <CardContent>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={`${classes.componentSpaceBetween} ${classes.mb2}`}
          >
            <Typography variant="h5">Advanced Filter</Typography>
            <FilterClose onClick={() => {setPopup(""); setIsFilterOpen(false)}}></FilterClose>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={2} xs={12} sm={12} md={12} lg={12}>
              <Grid xs={12} sm={12} md={6} lg={6} item>
                {/* Type */}
                <RACSelect
                  defaultValue={filterReq.type ? filterReq.type : ""}
                  options={queueType}
                  loading ={queueType?.length>1 ? false: true}
                  inputLabel="Type"
                  onChange={(e) => {
                    setFilterReq({ ...filterReq , ["type"]: e.target.value });
                  }}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6} item>
                {/* Status */}
                <RACSelect
                  defaultValue={filterReq.status ? filterReq.status : ""}
                  options={queueStatusType}
                  loading ={queueStatusType?.length>1 ? false: true}
                  inputLabel="Status"
                  onChange={(e) => {
                    setFilterReq({ ...filterReq, ["status"]: e.target.value });
                  }}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6} item>
                {/* Schedule */}
                <RACSelect
                  defaultValue={filterReq.schedule ? filterReq.schedule : ""}
                  options={schedule}
                  loading ={schedule?.length>1 ? false: true}
                  inputLabel="Schedule"
                  onChange={(e) => {
                    setFilterReq({
                      ...filterReq,
                      ["schedule"]: e.target.value,
                    });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} xs={12} sm={12} md={12} lg={12}>
              <Grid xs={12} sm={12} md={6} lg={6} item>
                {filterReq.toDate ? (
                  <RACDatePicker
                    label={"From"}
                    inputProps={{
                      
                      max: moment(filterReq.toDate).format("YYYY-MM-DD"),
                    }}
                    value={filterReq.fromDate}
                    onChange={(e: any) =>
                      setFilterReq({ ...filterReq, ["fromDate"]: e })
                    }
                  />
                ) : (
                  <RACDatePicker
                    label={"From"}
                    // inputProps={{
                    //   min: moment().format("YYYY-MM-DD"),
                    // }}
                    value={filterReq.fromDate}
                    onChange={(e: any) =>
                      setFilterReq({ ...filterReq, ["fromDate"]: e })
                    }
                  />
                )}
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6} item>
                {filterReq.fromDate ? 
                <RACDatePicker
                    label={"To"}
                    inputProps={{
                      
                      min: moment(filterReq.fromDate).format("YYYY-MM-DD"),
                    }}
                    value={filterReq.toDate}
                    onChange={(e: any) => {
                      debugger;
                      const date1 = moment(e, "YYYY-MM-DD");
                      const date2 = moment(filterReq.fromDate, "YYYY-MM-DD");
                      if (date2.isAfter(date1))
                        setFilterReq({
                          ...filterReq,
                          ["toDate"]: e,
                          ["fromDate"]: e,
                        });
                      else
                        setFilterReq({
                          ...filterReq,
                          ["toDate"]: e,
                        });
                    }}
                  />
                  :
                  <RACDatePicker
                  label={"To"}
                 
                  value={filterReq.toDate}
                  onChange={(e: any) => {
                    debugger;
                    const date1 = moment(e, "YYYY-MM-DD");
                    const date2 = moment(filterReq.fromDate, "YYYY-MM-DD");
                    if (date2.isAfter(date1))
                      setFilterReq({
                        ...filterReq,
                        ["toDate"]: e,
                        ["fromDate"]: e,
                      });
                    else
                      setFilterReq({
                        ...filterReq,
                        ["toDate"]: e,
                      });
                  }}
                />}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={` ${classes.mt4}  ${classes.componentRight}`}
          >
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.mr2}
              onClick={() => {
                setFilterReq({});
              }}
            >
              Clear
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              className={classes.mr2}
              disabled={ isFranchiseeUser && companyCode.length == 0 ? true : false}
              onClick={() => {
                              arr= Object.keys(filterReq)
                              arr.forEach((item : any )=>{if(!filterReq[item]) delete filterReq[item]})
                              filterReq.companyCode = isFranchiseeUser
                                ? companyCode
                                : undefined;
                                filterReq.offset ="0"
                              fetchPricingQueueDetail(filterReq);
                              setPopup("");
                              setTotalRowCount(0);
                              setPageNumber(0);
                            }}
                          >
              Apply
            </RACButton>
          </Grid>
        </CardContent>
      </Card>
    );
  };
  const pricingHeaderArr = [
    {
      label: "Type",
      key: "pricingType",
    },
    {
      label: "Weekly Rate New",
      key: "weeklyRateNew",
    },
    {
      label: "Weekly Rate Used",
      key: "weeklyRateUsed",
    },
    {
      label: "Term",
      key: "term",
    },
    {
      label: "Cash Price Factor",
      key: "cashPriceFactor",
    },
    {
      label: "Forced Cash Price",
      key: "forceCashPrice",
    },
    {
      label: "Manual PO-Turns",
      key: "ManualPOTurns",
    },
    {
      label: "Start Date",
      key: "startDate",
    },
    {
      label: "End Date",
      key: "endDate",
    },
  ];
  const storeZoneHeader = [
    {
      label: "Store",
      key: "storeNumber",
    },
    {
      label: "Zone",
      key: "zoneNumber",
    },
    {
      label: "Zone Name",
      key: "zoneName",
    },
    {
      label: "Company Name",
      key: "companyName",
    },
    {
      label: "Company Code",
      key: "companyCode",
    },
    {
      label: "Store Type",
      key: "storeType",
    },
    {
      label: "State",
      key: "state",
    },
    {
      label: "Start Date",
      key: "startDate",
    },
  ];
  {
    console.log(approvalPopup, "The approval popup");
  }
  const updatePricingQueueStatus = async (params: any) => {
    try {
      setFullLoader(true);
      setIsAlterOpen(false)
      let req = {
        pricingQueueId: indexValue.queueId.toString(),
        status: params,
        comments: comments ? comments : undefined,
        actionBy: sessionStorage.getItem("userName"),
      };
      const response = await updatePricingQueueStatusAPI(req);
      setAlterPopUp();

      if (response.status == 200) {
        setFullLoader(false);
        filterReq.companyCode=isFranchiseeUser ? companyCode : undefined;
        await fetchPricingQueueDetail(filterReq);
        setIsApproved(true)
      } else {
        setSomeThingWentWrng(true);
        setFullLoader(false);
      }
    } catch (e) {
      setFullLoader(false);
    }
  };
  let uniqueZones;
  let uniqueRmsItemNumbers;
  const pricingApprovalPopup = () => {
    {
      if (approvalPopup == "pricing") {
        uniqueZones = [
          ...new Set(
            indexValue.pricing[0].zoneDetails.map((item: any) => item.zone)
          ),
        ];
        uniqueRmsItemNumbers = [
          ...new Set(
            indexValue.pricing[0].zoneDetails.flatMap(
              (item: any) => item.rmsItemNumber
            )
          ),
        ];
        console.log(uniqueZones, "The uniqueZone", uniqueRmsItemNumbers);
      }
    }
    return (
      <>
        <Grid className={classes.p4} style={{paddingTop:'0.5rem'}}>
          <Grid container spacing={2}>
            {approvalPopup == "pricing" ? (
              <>
                {pricingHeaderArr.map((item: any) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Typography
                        className={`${classes.fontBold} ${classes.p2}}`}
                        style={{
                          paddingBottom: "5%",
                          fontFamily: "OpenSans-bold",
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        className={classes.fontSemiBold}
                        style={{ fontFamily: "OpenSans-semibold" }}
                      >
                        {item.key == pricingHeaderArr[1].key ||
                          item.key == pricingHeaderArr[2].key
                          ? `$${indexValue.pricing[0][item.key]
                          }`
                          : item.key == pricingHeaderArr[7].key ||
                            item.key == pricingHeaderArr[8].key
                            ? moment(indexValue.pricing[0][item.key]).format(
                              "MM/DD/YYYY"
                            )
                            :  indexValue.pricing[0][item.key]
                                ? indexValue.pricing[0][item.key]
                                : "-"}
                      </Typography>
                    </Grid>
                  );
                })}
                {/* <Grid md={12}>
                  <RACCheckBox label="Clearance" size="medium" disabled />
                </Grid> */}
                <Grid spacing={4} style={{ marginTop: "1%" }}>
                  <Typography style={{fontFamily: "OpenSans-bold"}}>
                    {" "}
                    How do you want to update the pricing?
                  </Typography>
                  <Grid
                    style={{
                      margin: "2%",
                    }}
                  >
                    <RACRadio
                      value={"IMMEDIATE"}
                      checked={
                        indexValue.schedule == "IMMEDIATE" ? true : false
                      }
                      label="Immediately"
                      name="updateType"
                      disabled={true}
                    ></RACRadio>
                    <RACRadio
                      value={"OVERNIGHT"}
                      checked={
                        indexValue.schedule == "OVERNIGHT" ? true : false
                      }
                      label="Overnight"
                      name="updateType"
                      disabled={true}
                    ></RACRadio>
                  </Grid>
                  <Typography>
                    <span style={{ fontFamily: "OpenSans-bold" }}>Note: </span>
                    <span
                      style={{
                        fontWeight: "lighter",
                        fontFamily: "OpenSans-semibold",
                        color: "gray",
                      }}
                    >
                      The selected criteria matches with&nbsp;
                      <a
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          fontFamily: "OpenSans-semibold",
                          textDecoration:'underline'
                        }}
                        onClick={() => {
                          setZoneDetails(true);
                          setIsapprovalPopup(false);
                        }}
                      >
                        {uniqueZones.length}&nbsp;Zones and{" "}
                        {uniqueRmsItemNumbers.length}&nbsp;RMS item # records.
                      </a>
                    </span>
                  </Typography>
                </Grid>
              </>
            ) : approvalPopup.toLowerCase() == "storezone" ? (
              <>
                {storeZoneHeader.map((item: any) => {
                  console.log("Hello storeZOne", storeZoneHeader);

                  return (
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Typography
                        className={`${classes.fontBold}${classes.p2}}`}
                        style={{ paddingBottom: "5%", fontFamily:'OpenSans-bold' }}
                      >
                        {item.label}
                      </Typography>
                      {indexValue?.storeZone &&
                        indexValue?.storeZone[0][item.key] ? (
                        <Typography style={{ fontFamily:'OpenSans-semibold'}}
                          className={classes.fontSemiBold}
                          title={
                            indexValue?.storeZone
                              ? indexValue?.storeZone[0][item.key].length > 25
                                ? indexValue?.storeZone[0][item.key].slice(
                                  0,
                                  25
                                ) + "..."
                                : ""
                              : ""
                          }
                        >
                          {item.key == pricingHeaderArr[8].key
                            ? moment(indexValue?.storeZone[0][item.key]).format(
                              "MM-DD-YYYY"
                            )
                            : indexValue?.storeZone[0][item.key]
                              ? indexValue?.storeZone[0][item.key].length > 25
                                ? indexValue?.storeZone[0][item.key].slice(
                                  0,
                                  25
                                ) + "..."
                                : indexValue?.storeZone[0][item.key]
                              : "-"}{" "}
                        </Typography>
                      ) : null}
                    </Grid>
                  );
                })}
                <Grid spacing={4} item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "1%" }}>
                  <Typography style={{fontFamily: "OpenSans-bold"}}>
                    {" "}
                    How do you want to update the storezone?
                  </Typography>
                  <Grid
                    style={{
                      margin: "2%",
                    }}
                  >
                    <RACRadio
                      value={"IMMEDIATE"}
                      checked={
                        indexValue.schedule == "IMMEDIATE" ? true : false
                      }
                      label="Immediately"
                      name="updateType"
                      disabled={true}
                    ></RACRadio>
                    <RACRadio
                      value={"OVERNIGHT"}
                      checked={
                        indexValue.schedule == "OVERNIGHT" ? true : false
                      }
                      label="Overnight"
                      name="updateType"
                      disabled={true}
                    ></RACRadio>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>

          <Typography variant="h4" style={{ marginTop: "2%" }}>
            Comments
          </Typography>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <textarea
              rows={6}
              style={{ resize: "none", width: "100%", border:'1px solid lightgray !important' }}
              disabled={
                indexValue?.status?.toUpperCase() == "QUEUED" ||
                  indexValue?.status?.toUpperCase() == "WAITING"
                  ? false
                  : true
              }
              value={comments}
              onChange={(e: any) => {
                setComments(e.target.value);
              }}
            ></textarea>
          </Grid>
          <Grid
            style={{
              float: "right",
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              marginTop: "2%",
              height: "45px",
            }}
            md={12}
          >
            {(indexValue?.status?.toUpperCase() == "WAITING" ||
              indexValue?.status?.toUpperCase() == "PENDING") /* && isApprover */ ? (
              <>
                <RACButton
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ float: "right", backgroundColor: "#2CD19D" }}
                  disabled={isApprover ? false : true}
                  onClick={() => {

                    setAlterPopUp({
                      text: `approve ${approvalPopup.toLowerCase() == "pricing"
                          ? "Pricing"
                          : approvalPopup.toLowerCase() == "storezone"
                            ? "StoreZone"
                            : ""
                        }`,
                      value: "A",
                    });
                    setIsRejected(false)
                    setIsapprovalPopup(false);
                    setIsAlterOpen(true);
                    // setApprovalPopup("");

                    // if(addPricingReq.pricingType!= 'TEMPORARY' && addPricingReq.endDate =='');
                    //need to add start date & end date validation
                  }}
                >
                  Approve
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ backgroundColor: "#E85C5C", marginRight: "2%" }}
                  disabled={comments && isApprover  ? false : true}
                  onClick={() => {
                    setAlterPopUp({
                      text: `reject ${approvalPopup.toLowerCase() == "pricing"
                          ? "Pricing"
                          : approvalPopup.toLowerCase() == "storezone"
                            ? "StoreZone"
                            : ""
                        }`,
                      value: "R",
                    });
                    setIsapprovalPopup(false);
                    setIsAlterOpen(true);
                    setIsRejected(true);
                    // setApprovalPopup("");

                    // if(addPricingReq.pricingType!= 'TEMPORARY' && addPricingReq.endDate =='');
                    //need to add start date & end date validation
                  }}
                >
                  Reject
                </RACButton>
              </>
            ) : indexValue?.status?.toUpperCase() == "QUEUED" &&
              indexValue.schedule.toUpperCase() == "OVERNIGHT" /*  && isApprover  */ ? (
              <RACButton
                variant="contained"
                color="primary"
                size="small"
                style={{ backgroundColor: "#E85C5C", marginRight: "2%" }}
                disabled={isApprover ? false : true}
                onClick={() => {
                  setAlterPopUp({
                    text: `cancel ${
                      approvalPopup.toLowerCase() == "pricing"
                        ? "pricing"
                        : approvalPopup.toLowerCase() == "storezone"
                          ? "storeZone"
                          : ""
                      }`,
                    value: "C",
                  });
                  setIsapprovalPopup(false);
                  setIsRejected('C');
                  setIsAlterOpen(true);
                  // setApprovalPopup("");

                  // if(addPricingReq.pricingType!= 'TEMPORARY' && addPricingReq.endDate =='');
                  //need to add start date & end date validation
                }}
              >
                Cancel Approval
              </RACButton>
            ) : (
              <></>
            )}
            <RACButton
              variant="outlined"
              color="primary"
              size="small"
              style={{ float: "right", marginRight: "2%" }}
              onClick={() => {
                setIsapprovalPopup(false);
                setComments("");
              }}
            >
              Cancel
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  let arr: any = [];
  const renderRmsDetailsTableBody = () => {
    arr = indexValue.pricing[0].zoneDetails;
    console.log(indexValue.pricing[0].zoneDetails, "tje zone ");

    return (
      <>
        {arr?.map((item: any, index: any) => (
          <React.Fragment key={index}>
            {item.rmsItemNumber.map((rmsItemNumber: any) => (
              <RACTableRow key={rmsItemNumber} style={{ background: "white" }}>
                <RACTableCell
                  style={{ fontFamily: "OpenSans-semibold", color: "gray" }}
                >
                  {item.zone}
                </RACTableCell>
                <RACTableCell
                  style={{ fontFamily: "OpenSans-semibold", color: "gray" }}
                >
                  {rmsItemNumber}
                </RACTableCell>
              </RACTableRow>
            ))}
          </React.Fragment>
        ))}
      </>
    );
  };
  const renderRmsDetailsTableHeader = () => {
    return (
      <>
        <RACTableCell>Zone</RACTableCell>
        <RACTableCell>RMS Item #</RACTableCell>
      </>
    );
  };
  const rmsSelectedPopup = () => {
    return (
      <Grid className={classes.zoneDetailHeight}>
        <RACTable
          renderTableContent={renderRmsDetailsTableBody}
          renderTableHead={renderRmsDetailsTableHeader}
          style={{
            width: "100%",
            background: "white",
            whiteSpace: "nowrap",
          }}
        />
      </Grid>
    );
  };
  const alterPopupFn = () => {
    return (
      <>
        <Grid>
          <Grid style={{ textAlign: "center", marginBottom: "2%" }}>
            <SuccessIcon />
          </Grid>
          <Grid>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              {`Do you want to ${alterPopUp?.text}`}
            </Typography>
          </Grid>
          <Grid
            style={{ display: "flex", justifyContent: "center", margin: "5%" }}
          >
            <RACButton
              style={{ marginRight: "10%" }}
              variant="outlined"
              onClick={() => {
                setIsAlterOpen(false);
                

              }}
            >
              No
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => {
                updatePricingQueueStatus(alterPopUp.value);
              }}
            >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <>
      <Grid container md={12} style={{ padding: "0.5% 0%" }}>
        <Grid item md={11} style={{ padding: "0.5% 0%" }}>
          <Typography variant="h4"> Pricing Queue</Typography>
        </Grid>
        <Grid item md={1} style={{float:'right'}}>
          <RACButton
            variant="contained"
            color="primary"
            size="small"
            style={{ cursor: "pointer",float:'right' }}
            onClick={() => {
              setPopup(popup? "" : "filter");
              setIsFilterOpen(!isFilterOpen);
            }}
          >
            <RacFilter></RacFilter>
            <span style={{ margin: "0px 8px" }}>Filter</span>
            {/* Need to add filter icon !important */}
            {/* {isFilterOpen ? <AccordianClose /> : <AccordianOpen />} */}
          </RACButton>
        </Grid>
      </Grid>
      {popup == "filter" ? openFilterpopup() : null}
      <RACModalCard
        isOpen={isapprovalPopup }
        title={
          approvalPopup == "pricing" ? "Pricing Approval" : "StoreZone Approval"
        }
        maxWidth="md"
        closeIcon={true}
        onClose={() => {
           setIsapprovalPopup(false);
          }}
        borderRadius={10}
        titleVariant="h4"
        >
        {pricingApprovalPopup()}
      </RACModalCard>
      <Grid>
        <RACModalCard
          isOpen={zoneDetails}
          title="Zone Details"
          maxWidth="xs"
          closeIcon={true}
          onClose={() => {
            setApprovalPopup('pricing')
            setIsAlterOpen(false)
            setZoneDetails(false)
            setIsapprovalPopup(true);
          }}
          borderRadius={10}
          titleVariant="h4"
          TitleClassName={classes.customTitle}
          className={classes.modelCardZoneDetail}
        >
          {rmsSelectedPopup()}
        </RACModalCard>
      </Grid>
      <RACModalCard
        isOpen={isAlterOpen ? true : false}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => {
          setIsAlterOpen(false)
          }}
        children={alterPopupFn()}
        title=""
      />
      {
        fullLoader? <>
        <Grid className={progressclasses.masterLoader}>
          <Grid className={progressclasses.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
        </>: null
      }
    </>
  );
}
